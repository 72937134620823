import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import rootReducer from './routeReducer'

const store =
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
        ? createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))
        : createStore(rootReducer, applyMiddleware(thunk))
export default store
