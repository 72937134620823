export const gpaToLetterGradeMap = {
    0: 'F',
    0.7: 'D-',
    1: 'D',
    1.3: 'D+',
    1.7: 'C-',
    2: 'C',
    2.3: 'C+',
    2.7: 'B-',
    3: 'B',
    3.3: 'B+',
    3.7: 'A-',
    4: 'A+/A'
}; 

export const letterGradeCutoffsMap = {
    'F': 0,
    'D-': 0.7,
    'D': 1,
    'D+': 1.3,
    'C-': 1.7,
    'C': 2,
    'C+': 2.3,
    'B-': 2.7,
    'B': 3,
    'B+': 3.3,
    'A-': 3.7,
    'A+/A': 4
}; 

export const getLetterGrades = function(){
    return Object.keys(letterGradeCutoffsMap).reverse()
}
export const findLetterGradeCutoff = function(letterGrade){
    return letterGradeCutoffsMap[letterGrade]
}

export const findLetterGrade = function(gpa) {
    const gpaNum = Number(gpa)
    const keys = Object.keys(gpaToLetterGradeMap).map((stringKey) => Number(stringKey))
    keys.sort()
    let prev = -1;
    let index = 0;
    while (keys[index] <= gpaNum) {
        prev = index
        index += 1
    }
    return gpaToLetterGradeMap[keys[prev]]
}