import React from 'react'
import PropTypes from 'prop-types'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
} from '@mui/material'
import { useHistory } from 'react-router-dom'

function LoginPrompt({ message, open, onClose }) {
    const history = useHistory()
    const handleClose = () => {
        onClose()
    }
    const handleLogin = () => {
        onClose()
        history.push('/login')
    }
    return (
        <Dialog
            aria-describedby="alert-dialog-description"
            aria-labelledby="alert-dialog-title"
            onClose={handleClose}
            open={open}
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button autoFocus onClick={handleLogin}>
                    Login
                </Button>
            </DialogActions>
        </Dialog>
    )
}

LoginPrompt.propTypes = {
    message: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool,
}

LoginPrompt.defaultProps = {
    message: 'Only logged in users can access this. Proceed to login?',
    open: false,
}

export default LoginPrompt
