import axios from 'axios'

import { JOB_REQUEST, JOB_SUCCESS, JOB_FAILURE, JOB_CHANGE } from './jobTypes'

export const jobRequest = () => ({
    type: JOB_REQUEST,
})

export const jobSuccess = (jobData) => ({
    type: JOB_SUCCESS,
    payload: jobData,
})

export const jobFailure = (errorMsg) => ({
    type: JOB_FAILURE,
    payload: errorMsg,
})

export const jobChange = (errorMsg) => ({
    type: JOB_CHANGE,
    payload: errorMsg,
})

export const jobSearch =
    (searchValue, locationValue, countryValue) => async (dispatch) => {
        dispatch(jobChange)

        await axios
            .get(`${process.env.REACT_APP_BASE_URL}/jobs`, {
                params: {
                    search: searchValue,
                    location: locationValue,
                    country: countryValue,
                },
            })
            .then((res) => {
                const jobData = res.data
                if (!jobData) {
                    dispatch(jobFailure('Job data not available'))
                } else {
                    dispatch(jobSuccess(jobData))
                }
            })
            .catch((err) => {
                dispatch(
                    jobFailure(
                        (err.response && err.response.data) ||
                            'Error fetching jobs'
                    )
                )
            })
    }
