import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import {
    AppBar,
    Avatar,
    Drawer,
    IconButton,
    MenuItem,
    Toolbar,
} from '@mui/material'
import { Menu as MenuIcon } from '@mui/icons-material'
import { logout } from '../../redux'

function NavBar() {
    const dispatch = useDispatch()
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 900)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [scrolledDown, setScrolledDown] = useState(false)

    const userLoggedIn = useSelector(
        (state) =>
            state.userData &&
            state.userData.user &&
            state.userData.user.id !== undefined
    )
    const userData = useSelector((state) => state.userData)
    const isFetchingUser = useSelector(
        (state) => state.userData && state.userData.loading
    )

    function handleWindowSizeChange() {
        setIsMobileView(window.innerWidth < 900)
    }

    useEffect(() => {
        const handleScroll = (event) => {
            if (
                event.srcElement.body.scrollTop > 50 ||
                event.srcElement.documentElement.scrollTop > 50
            ) {
                setScrolledDown(true)
            } else {
                setScrolledDown(false)
            }
        }
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    }, [])

    const getDrawerChoices = () => {
        if (isFetchingUser) {
            return (
                <MenuItem
                    sx={{
                        backgroundColor: 'inherit',
                        color: '#fff',
                    }}
                >
                    Loading User...
                </MenuItem>
            )
        }
        if (!userLoggedIn) {
            return (
                <NavLink onClick={() => setIsDrawerOpen(false)} to="/login">
                    <MenuItem
                        sx={{
                            backgroundColor: 'inherit',
                            color: '#fff',
                        }}
                    >
                        Login
                    </MenuItem>
                </NavLink>
            )
        }
        const choices = [
            { label: 'My Courses', url: '/courses' },
            { label: 'Grade Analyzer', url: '/grade-analyzer' },
            { label: 'GPA Analyzer', url: '/gpa-analyzer' },
            { label: 'Quiz', url: '/quiz/categories' },
            {
                label: 'Logout',
                url: '#',
                onClick: () => {
                    setIsDrawerOpen(false)
                    dispatch(logout())
                },
            },
        ].map(({ label, url, onClick }) => (
            <NavLink
                key={label}
                onClick={() => setIsDrawerOpen(false)}
                to={url}
            >
                <MenuItem
                    className="mt-2 mb-2"
                    onClick={onClick || (() => {})}
                    sx={{
                        backgroundColor: 'inherit',
                        color: '#fff',
                        fontSize: '1.1rem',
                    }}
                >
                    {label}
                </MenuItem>
            </NavLink>
        ))
        if (isMobileView) {
            choices.unshift(
                <MenuItem>
                    <div className="ml-1 mt-4 mr-2 mb-2 row">
                        <Avatar
                            alt={userData?.user?.firstName}
                            className="img-fluid"
                            src="/images/UserAvatarLogo.png"
                        />
                        <span
                            className="ml-2"
                            style={{
                                alignSelf: 'center',
                                color: '#fff',
                                fontSize: '1.3rem',
                            }}
                        >
                            {userData?.user?.firstName}{' '}
                            {userData?.user?.lastName}
                        </span>
                    </div>
                </MenuItem>
            )
        }
        return choices
    }

    return (
        <header>
            <AppBar sx={{ backgroundColor: '#0055A2' }}>
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <Link className="navbar-brand" to="/">
                        <img
                            alt="logo"
                            id="nav-logo"
                            src="/logo.png"
                            style={{
                                width: scrolledDown ? '40px' : '70px',
                                height: scrolledDown ? '40px' : '70px',
                                margin: scrolledDown ? '8px 12px' : '8px 12px',
                            }}
                        />
                        <h4 className="ml-4" style={{ alignSelf: 'center' }}>
                            <i>my</i>Academic
                        </h4>
                    </Link>
                    <Toolbar>
                        {userLoggedIn && !isMobileView ? (
                            <div className="mr-5 row">
                                <Avatar
                                    alt={userData?.user?.firstName}
                                    className="img-fluid"
                                    src="/images/UserAvatarLogo.png"
                                />
                                <span
                                    className="ml-2"
                                    style={{
                                        alignSelf: 'center',
                                        color: '#fff',
                                        fontSize: '1.3rem',
                                    }}
                                >
                                    {' '}
                                    {userData?.user?.firstName}{' '}
                                    {userData?.user?.lastName}
                                </span>
                            </div>
                        ) : null}
                        <IconButton
                            aria-haspopup="true"
                            aria-label="menu"
                            color="inherit"
                            edge="start"
                            onClick={() => {
                                setIsDrawerOpen(!isDrawerOpen)
                            }}
                            style={{
                                outline: 'none',
                            }}
                        >
                            <MenuIcon fontSize="large" />
                        </IconButton>
                        <Drawer
                            anchor="right"
                            onClose={() => {
                                setIsDrawerOpen(false)
                            }}
                            open={isDrawerOpen}
                            SlideProps={{
                                sx: {
                                    backgroundColor: '#0055A2',
                                    minWidth: '200px',
                                },
                            }}
                        >
                            <div
                                className={
                                    userLoggedIn && isMobileView ? '' : 'pt-5'
                                }
                            >
                                {getDrawerChoices()}
                            </div>
                        </Drawer>
                    </Toolbar>
                </div>
            </AppBar>
        </header>
    )
}

export default NavBar
