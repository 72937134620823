import React from 'react'
import PropTypes from 'prop-types'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton, Menu, MenuItem } from '@mui/material'

function VerticalDotsMenu({ onClick }) {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <div>
            <IconButton
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                aria-label="more"
                id="long-button"
                onClick={handleClick}
                style={{ outline: 'none' }}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                onClose={handleClose}
                open={open}
                PaperProps={{
                    style: {
                        maxHeight: 48 * 4.5,
                        width: '20ch',
                    },
                }}
            >
                <MenuItem onClick={onClick}>Delete</MenuItem>
            </Menu>
        </div>
    )
}

VerticalDotsMenu.propTypes = {
    onClick: PropTypes.func.isRequired,
}

export default VerticalDotsMenu
