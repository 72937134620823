import React, { useState } from 'react'
import PropTypes from 'prop-types'
import EditIcon from '@mui/icons-material/Edit'
import EditOffIcon from '@mui/icons-material/EditOff'
import { useDispatch, useSelector } from 'react-redux'
import { TextField, Typography } from '@mui/material'
import {
    courseMeetingDetailsUpdate,
    updateCourseFields,
} from '../../../../redux'
import { addAnchorTagsForLinksInText } from '../../../../utils'

export default function MeetingDetails({ courseId, courseMeetingDetails }) {
    const dispatch = useDispatch()
    const errorData = useSelector((state) => state.errorData)
    const [enableEdit, setEnableEdit] = useState(false)

    const handleEditButtonClick = () => {
        if (enableEdit) {
            dispatch(
                updateCourseFields(courseId, {
                    meetingDetails: courseMeetingDetails,
                })
            )
        }
        setEnableEdit(!enableEdit)
    }

    return (
        <div
            style={{
                textAlign: 'left',
                display: 'flex',
                flexDirection: 'column',
                minHeight: '70vh',
            }}
        >
            {enableEdit ? (
                <TextField
                    error={!!errorData.meetingDetails}
                    fullWidth
                    helperText={`${courseMeetingDetails.length}/512 characters`}
                    id="description-edit"
                    inputProps={{
                        style: {
                            padding: 0,
                            minHeight: '23.5%',
                        },
                    }}
                    multiline
                    onBlur={handleEditButtonClick}
                    onChange={(e) =>
                        dispatch(courseMeetingDetailsUpdate(e.target.value))
                    }
                    onClick={(e) => e.stopPropagation()}
                    rows={4}
                    value={courseMeetingDetails}
                    variant="filled"
                />
            ) : (
                <Typography
                    id="description"
                    onClick={(e) => e.stopPropagation()}
                    style={{ whiteSpace: 'pre-line' }}
                    sx={{
                        flexGrow: 20,
                        display: '-webkit-box',
                        overflow: 'scroll',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 4,
                        background: 'rgb(247, 247, 247)',
                        minHeight: '7.8em',
                        padding: '25px 12px 8px',
                        border: errorData.description
                            ? '1px red solid'
                            : 'none',
                    }}
                    variant="body1"
                >
                    {addAnchorTagsForLinksInText(courseMeetingDetails)}
                </Typography>
            )}
            <button
                className="btn btn-primary m-2"
                onClick={handleEditButtonClick}
                style={{ width: 'fit-content' }}
                type="button"
            >
                {enableEdit ? (
                    <>
                        <EditOffIcon fontSize="small" />{' '}
                    </>
                ) : (
                    <>
                        <EditIcon fontSize="small" /> Edit
                    </>
                )}
            </button>
        </div>
    )
}

MeetingDetails.propTypes = {
    courseId: PropTypes.string.isRequired,
    courseMeetingDetails: PropTypes.string.isRequired,
}
