import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'

// import ReactQuiz from '@nnischal95/react-quiz-component'
import ReactQuiz from '@myacademic/react-quiz-component'

import TopLoader from '../commons/TopLoader'
import { fetchQuiz, quizClear } from '../../redux'
import { shuffleAnswerOptionsForAllQuestions } from '../../utils/quizHelper'

function Quiz() {
    const dispatch = useDispatch()
    const userData = useSelector((state) => state.userData)
    const quizData = useSelector((state) => state.quizData)
    const { quiz_id: quizId } = useParams()
    useEffect(() => {
        window.scrollTo(0, 0)
        if (
            userData.userFetchCompleted &&
            userData.user &&
            userData.user.id !== undefined
        ) {
            dispatch(fetchQuiz(quizId))
        }
        return quizClear
    }, [dispatch, userData.userFetchCompleted, userData.user, quizId])
    if (userData && !userData.userFetchCompleted) {
        return <div className="container main-container mb-5" />
    }
    if (
        userData &&
        userData.userFetchCompleted &&
        (!userData.user || userData.user.id === undefined)
    ) {
        return <Redirect to="/" />
    }
    if (!quizData.attemptedFetch || quizData.loading) {
        return (
            <div className="container main-container">
                <TopLoader message={quizData.loadingMessage} />
            </div>
        )
    }
    if (quizData.error) {
        return (
            <div className="container main-container">
                <h4 className="alert alert-danger mt-4">{quizData.error}</h4>
            </div>
        )
    }

    shuffleAnswerOptionsForAllQuestions(quizData.quiz.data.questions)
    return (
        <div className="container main-container">
            <ReactQuiz
                category={quizData.quiz.category}
                quiz={quizData.quiz.data}
                quizId={quizData.quiz.id}
                showColorCode
                showInstantFeedback={false}
                shuffle
            />
        </div>
    )
}

export default Quiz
