import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import NavBar from './components/commons/NavBar'
import Footer from './components/commons/Footer'
import GradeAnalyzer from './components/GradeAnalyzer'
import GPAAnalyzer from './components/GPAAnalyzer'
import Login from './components/Login'
import Register from './components/Register'
import { getCurrentUser as getCurrentUserRedux } from './redux'
import TopLoader from './components/commons/TopLoader'
import VerifyUser from './components/VerifyUser'
import GenerateResetPasswordEmail from './components/GenerateResetPasswordEmail'
import ResetPassword from './components/ResetPassword'
import LandingPage from './components/LandingPage'
import Careers from './components/Careers'
import Quiz from './components/quiz/Quiz'
import QuizList from './components/quiz/QuizList'
import CoursesList from './components/courses/CoursesList'
import QuizCategories from './components/quiz/QuizCategories'
import ContactUs from './components/ContactUs'
import CoursesHome from './components/courses/CoursesHome'

function Routes({ userData, getCurrentUser }) {
    useEffect(() => {
        if (
            userData.user === undefined ||
            (userData.user && userData.user.id !== undefined)
        )
            return
        getCurrentUser()
    }, [userData.user, getCurrentUser])

    return (
        <>
            {userData && userData.loading ? (
                <TopLoader message={userData.loadingMessage} />
            ) : (
                ''
            )}
            <BrowserRouter>
                <NavBar />
                <Switch>
                    <Route component={LandingPage} exact path="/" />
                    <Route component={ContactUs} exact path="/contact-us" />
                    <Route component={Careers} exact path="/careers" />
                    <Route
                        component={GradeAnalyzer}
                        exact
                        path="/grade-analyzer"
                    />
                    <Route
                        component={GPAAnalyzer}
                        exact
                        path="/gpa-analyzer"
                    />
                    <Route component={Login} exact path="/login" />
                    <Route component={Register} exact path="/register" />
                    <Route component={VerifyUser} exact path="/verify-user" />
                    <Route
                        component={GenerateResetPasswordEmail}
                        exact
                        path="/reset-password/generate"
                    />
                    <Route
                        component={ResetPassword}
                        exact
                        path="/reset-password"
                    />
                    <Route component={CoursesList} exact path="/courses" />
                    <Route
                        component={QuizCategories}
                        exact
                        path="/quiz/categories"
                    />
                    <Route component={Quiz} exact path="/quiz/:quiz_id" />
                    <Route
                        component={QuizList}
                        exact
                        path="/quizzes/:category"
                    />
                    <Route
                        component={CoursesHome}
                        exact
                        path="/course/home/:course_id"
                    />
                    <Route
                        component={GradeAnalyzer}
                        exact
                        path="/course/:course_id"
                    />
                    <Route component={LandingPage} />
                </Switch>
                <Footer />
            </BrowserRouter>
        </>
    )
}

Routes.propTypes = {
    userData: PropTypes.shape({
        loading: PropTypes.bool,
        loadingMessage: PropTypes.string,
        user: PropTypes.shape({
            id: PropTypes.string,
        }),
    }).isRequired,
    getCurrentUser: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    userData: state.userData,
})

const mapDispatchToProps = (dispatch) => ({
    getCurrentUser: () => dispatch(getCurrentUserRedux()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Routes)
