import React, { useState } from 'react'
import PropTypes from 'prop-types'
import EditIcon from '@mui/icons-material/Edit'
import EditOffIcon from '@mui/icons-material/EditOff'
import { useDispatch, useSelector } from 'react-redux'
import { TextField, Typography } from '@mui/material'
import {
    courseSyllabusUpdate,
    setErrorForKey,
    updateCourseFields,
} from '../../../../redux'
import { addAnchorTagsForLinksInText } from '../../../../utils'

export default function Syllabus({ courseId, courseSyllabus }) {
    const dispatch = useDispatch()
    const errorData = useSelector((state) => state.errorData)
    const [enableSyllabusEdit, setEnableSyllabusEdit] = useState(false)

    const handleEditButtonClick = () => {
        if (enableSyllabusEdit) {
            dispatch(updateCourseFields(courseId, { syllabus: courseSyllabus }))
        }
        setEnableSyllabusEdit(!enableSyllabusEdit)
    }

    const handleSyllabusUpdate = (updatedSyllabus) => {
        let errorMessage = null
        if (updatedSyllabus.length > 1024) errorMessage = 'Syllabus too long'
        dispatch(setErrorForKey('syllabus', errorMessage))
        dispatch(courseSyllabusUpdate(updatedSyllabus))
    }

    return (
        <div
            style={{
                textAlign: 'left',
                display: 'flex',
                flexDirection: 'column',
                minHeight: '70vh',
            }}
        >
            {enableSyllabusEdit ? (
                <TextField
                    error={!!errorData.syllabus}
                    fullWidth
                    helperText={`${courseSyllabus.length}/1024 characters`}
                    id="description-edit"
                    inputProps={{
                        style: {
                            padding: 0,
                            minHeight: '23.5%',
                        },
                    }}
                    multiline
                    onBlur={handleEditButtonClick}
                    onChange={(e) => handleSyllabusUpdate(e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                    rows={4}
                    value={courseSyllabus}
                    variant="filled"
                />
            ) : (
                <Typography
                    id="description"
                    onClick={(e) => e.stopPropagation()}
                    style={{ whiteSpace: 'pre-line' }}
                    sx={{
                        flexGrow: 20,
                        display: '-webkit-box',
                        overflow: 'scroll',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 4,
                        background: 'rgb(247, 247, 247)',
                        minHeight: '7.8em',
                        padding: '25px 12px 8px',
                        border: errorData.description
                            ? '1px red solid'
                            : 'none',
                    }}
                    variant="body1"
                >
                    {addAnchorTagsForLinksInText(courseSyllabus)}
                </Typography>
            )}
            <button
                className="btn btn-primary m-2"
                disabled={enableSyllabusEdit && errorData.syllabus}
                onClick={handleEditButtonClick}
                style={{ width: 'fit-content' }}
                type="button"
            >
                {enableSyllabusEdit ? (
                    <>
                        <EditOffIcon fontSize="small" /> Save
                    </>
                ) : (
                    <>
                        <EditIcon fontSize="small" /> Edit
                    </>
                )}
            </button>
        </div>
    )
}

Syllabus.propTypes = {
    courseId: PropTypes.string.isRequired,
    courseSyllabus: PropTypes.string.isRequired,
}
