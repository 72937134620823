import { roundValueToDecimals } from '../../../utils'
import { CATEGORY_WEIGHT_DECIMAL_ACCURACY } from '../../../utils/constants'

export const getStandardCategoryReducedData = (
    category,
    achievingTargetGrade,
    gradesInAchievingTargetGrade
) => {
    let totalGrades = null
    let totalItemsWeight = 0
    let totalCategoryMaximumPoints = 0
    let knownGradesSum = 0
    let knownGradesWeightSum = 0
    let unknownGradesWeightSum = 0
    let unknownGradesCount = 0
    category.items.forEach((item, itemIdx) => {
        const { knownScores, grade, maximumPoints, weight } = item
        if (achievingTargetGrade && !knownScores) {
            totalGrades +=
                maximumPoints > 0
                    ? (gradesInAchievingTargetGrade[itemIdx] / maximumPoints) *
                      weight
                    : 0
        } else if (knownScores) {
            totalGrades = totalGrades === null ? 0 : totalGrades
            totalGrades +=
                maximumPoints > 0 ? (grade / maximumPoints) * weight : 0
        }
        totalItemsWeight += weight
        totalCategoryMaximumPoints += maximumPoints
        if (knownScores) {
            knownGradesSum +=
                maximumPoints > 0 ? (grade / maximumPoints) * weight : 0
            knownGradesWeightSum += weight
        } else {
            unknownGradesWeightSum += weight
            unknownGradesCount += 1
        }
    })
    return {
        totalGrades, // Total grades can be actual grades or achievable grades
        totalItemsWeight,
        totalCategoryMaximumPoints,
        totalCategoryWeight: totalItemsWeight,
        knownGradesSum, // Similar to totalGrades but it's always sum of actual grades
        knownGradesWeightSum,
        unknownGradesWeightSum,
        unknownGradesCount,
    }
}

export const getTotalItemsWeightOfStandardCategory = (category) =>
    roundValueToDecimals(
        category.items.reduce((weightSum, item) => weightSum + item.weight, 0),
        CATEGORY_WEIGHT_DECIMAL_ACCURACY
    )

export const getWeightOfStandardCategory = getTotalItemsWeightOfStandardCategory

export const getMaximumPointsOfStandardCategory = (category) =>
    category.items.reduce((sum, item) => sum + item.maximumPoints, 0)

export const getItemWeightsOfStandardCategory = (category) =>
    category.items.map((item) =>
        roundValueToDecimals(item.weight, CATEGORY_WEIGHT_DECIMAL_ACCURACY)
    )

export const updateCategoriesWithTypeUpdateToStandard = (
    categories,
    categoryIndex
) => {
    categories[categoryIndex].items.forEach((item) => {
        if (!item.weight) item.weight = 0
    })
}

const getCleanStandardCategoryItem = (item) => {
    const { name, description, weight, knownScores, grade, maximumPoints } =
        item
    return {
        name,
        description,
        weight,
        knownScores,
        grade,
        maximumPoints,
    }
}

export const getCleanStandardCategory = (category) => {
    const { name, categoryType, weight, items } = category
    return {
        name,
        categoryType,
        weight,
        items: items.map(getCleanStandardCategoryItem),
    }
}
