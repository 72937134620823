import axios from 'axios'
import {
    QUIZ_CLEAR,
    QUIZ_FAILURE,
    QUIZ_REQUEST,
    QUIZ_SUCCESS,
} from './quizTypes'

export const quizRequest = () => ({
    type: QUIZ_REQUEST,
})

export const quizSuccess = (quiz) => ({
    type: QUIZ_SUCCESS,
    payload: quiz,
})

export const quizFailure = (errorMsg) => ({
    type: QUIZ_FAILURE,
    payload: errorMsg,
})

export const quizClear = () => ({
    type: QUIZ_CLEAR,
})

export const fetchQuiz = (id) => (dispatch) => {
    dispatch(quizRequest())
    axios
        .get(`${process.env.REACT_APP_BASE_URL}/quiz/${id}`)
        .then((res) => {
            const quiz = res.data
            if (!quiz || quiz.length === 0) {
                dispatch(quizFailure('Quiz not found'))
            } else {
                dispatch(quizSuccess(quiz))
            }
        })
        .catch((err) => {
            dispatch(
                quizFailure(
                    (err.response && err.response.data) ||
                        'Error loading the quiz'
                )
            )
        })
}
