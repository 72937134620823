import {
    QUIZ_META_FAILURE,
    QUIZ_META_REQUEST,
    QUIZ_META_SUCCESS,
} from './quizMetaTypes'

const initialState = {
    loading: false,
    loadingMessage: '',
    fetchSuccess: false,
    quizMetas: [],
    message: '',
    error: '',
}

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case QUIZ_META_REQUEST:
            return {
                ...state,
                loading: true,
                loadingMessage: 'Fetching available quizzes...',
                fetchSuccess: false,
                quizMetas: [],
                message: '',
                error: '',
            }
        case QUIZ_META_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                fetchSuccess: true,
                quizMetas: action.payload,
                message: '',
                error: '',
            }
        case QUIZ_META_FAILURE:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                fetchSuccess: false,
                quizMetas: [],
                message: '',
                error: action.payload,
            }
        default:
            return state
    }
}

export default reducer
