import { JOB_REQUEST, JOB_SUCCESS, JOB_FAILURE, JOB_CHANGE } from './jobTypes'

const initialState = {
    jobs: [],
    message: '',
    error: '',
}

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case JOB_CHANGE:
            return []
        case JOB_REQUEST:
            return {
                ...state,
                jobs: [],
                message: '',
                error: '',
            }
        case JOB_SUCCESS:
            return {
                jobs: action.payload,
                message: '',
                error: '',
            }
        case JOB_FAILURE:
            return {
                ...state,
                jobs: [],
                message: '',
                error: '',
            }
        default:
            return state
    }
}

export default reducer
