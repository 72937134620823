import {
    Box,
    IconButton,
    SpeedDial,
    SpeedDialAction,
    SpeedDialIcon,
    Tab,
    Tabs,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import React, { useState, useEffect } from 'react'
import { SketchField, Tools } from 'react-sketch2'
import { TwitterPicker } from 'react-color'
import '../../../css/CourseHome.css'
import { Redirect, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import SaveIcon from '@mui/icons-material/Save'
import ModeIcon from '@mui/icons-material/Mode'
import ClearIcon from '@mui/icons-material/Clear'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import UndoIcon from '@mui/icons-material/Undo'
import RedoIcon from '@mui/icons-material/Redo'
import TextFieldsOutlinedIcon from '@mui/icons-material/TextFieldsOutlined'
import RectangleOutlinedIcon from '@mui/icons-material/RectangleOutlined'
import PanToolIcon from '@mui/icons-material/PanTool'
import MouseIcon from '@mui/icons-material/Mouse'
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule'
import {
    resetCourseData,
    clearErrorForKey,
    fetchSavedCourse,
} from '../../../redux'
import GradeAnalyzer from '../../GradeAnalyzer'
import TabPanel from '../../commons/TabPanel'
import Syllabus from './Tabs/Syllabus'
import MeetingDetails from './Tabs/MeetingDetails'

function CourseHome() {
    const dispatch = useDispatch()
    const userData = useSelector((state) => state.userData)
    const currentCourseData = useSelector((state) => state.currentCourseData)
    const user = userData && userData.user
    const { course_id: courseId = '' } = useParams()
    const [selectedOption, setSelectedOption] = useState(0)
    const [lineColor, setLineColor] = useState('000')
    const [backColor, setBackColor] = useState('white')
    const [fillColor, setFillColor] = useState('transparent')
    const [selectedTool, setSelectedTool] = useState(Tools.Pencil)
    const [lineColorShow, setLineColorShow] = useState('none')
    const [backColorShow, setBackColorShow] = useState('none')
    const [fillColorShow, setFillColorShow] = useState('none')
    const [open, setOpen] = useState(false)
    const options = [
        'Grade Analyzer',
        'Syllabus',
        'White Board',
        'Meeting details',
    ]

    const { courseName } = currentCourseData || {}

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(fetchSavedCourse(courseId))
        return () => {
            dispatch(resetCourseData())
            dispatch(clearErrorForKey('course'))
            dispatch(clearErrorForKey('target'))
        }
    }, [dispatch, userData.userFetchCompleted, userData.user, courseId])

    const handleLineColorChange = (color) => {
        setLineColor(color.hex)
        setLineColorShow('none')
    }
    const handleBackColorChange = (color) => {
        setBackColor(color.hex)
        setBackColorShow('none')
    }
    const handleFillColorChange = (color) => {
        setFillColor(color.hex)
        setFillColorShow('none')
    }

    const downloadImage = () => {
        const canvas = document.getElementsByTagName('canvas')
        const url = canvas[0].toDataURL('image/png')
        const link = document.createElement('a')
        link.download = 'whiteboard.png'
        link.href = url
        link.click()
    }

    const handleSelectedOption = (e) => {
        setSelectedOption(e)
    }

    const handleLineColorShow = () => {
        if (lineColorShow === 'none') {
            setLineColorShow('block')
            setBackColorShow('none')
            setFillColorShow('none')
        } else {
            setLineColorShow('none')
        }
    }

    const handleBackColorShow = () => {
        if (backColorShow === 'none') {
            setBackColorShow('block')
            setLineColorShow('none')
            setFillColorShow('none')
        } else {
            setBackColorShow('none')
        }
    }
    const handleFillColorShow = () => {
        if (fillColorShow === 'none') {
            setFillColorShow('block')
            setLineColorShow('none')
            setBackColorShow('none')
        } else {
            setFillColorShow('none')
        }
    }
    if (userData && !userData.userFetchCompleted) {
        return <div className="container main-container mb-5" />
    }
    if (
        userData &&
        userData.userFetchCompleted &&
        (!user || user.id === undefined)
    ) {
        return <Redirect to="/" />
    }

    const actions = [
        { icon: <SaveIcon />, name: 'Save', handleAction: downloadImage },
        {
            icon: <ModeIcon />,
            name: 'Pencil',
            handleAction: () => setSelectedTool(Tools.Pencil),
        },
        {
            icon: <ClearIcon />,
            name: 'Clear',
            handleAction: () => {
                // eslint-disable-next-line no-underscore-dangle
                CourseHome._sketch.clear()
                setBackColor('white')
            },
        },
        {
            icon: <UndoIcon />,
            name: 'Undo',
            handleAction: () =>
                // eslint-disable-next-line no-underscore-dangle
                CourseHome._sketch.canUndo() && CourseHome._sketch.undo(),
        },
        {
            icon: <RedoIcon />,
            name: 'Redo',
            handleAction: () =>
                // eslint-disable-next-line no-underscore-dangle
                CourseHome._sketch.canRedo() && CourseHome._sketch.redo(),
        },
        {
            icon: <TextFieldsOutlinedIcon />,
            name: 'Share',
            handleAction: () => {
                // eslint-disable-next-line no-underscore-dangle
                CourseHome._sketch.addText('New Text')
                setSelectedTool(Tools.Select)
            },
        },
    ]
    const renderTabContent = (tabIndex) => {
        switch (tabIndex) {
            case 0:
                return <GradeAnalyzer courseId={courseId} />
            case 1:
                return (
                    <Syllabus
                        courseId={courseId}
                        courseSyllabus={currentCourseData.courseSyllabus}
                    />
                )
            case 2:
                return (
                    <>
                        <div style={{ border: '1px solid black' }}>
                            <SketchField
                                ref={(c) => {
                                    // eslint-disable-next-line no-underscore-dangle
                                    CourseHome._sketch = c
                                }}
                                backgroundColor={backColor}
                                fillColor={fillColor}
                                lineColor={lineColor}
                                lineWidth={3}
                                name="sketch"
                                tool={selectedTool}
                                undoSteps={15}
                            />
                            <Box
                                sx={{
                                    transform: 'translateZ(0px)',
                                    flexGrow: 1,
                                }}
                            >
                                <SpeedDial
                                    ariaLabel="SpeedDial controlled open example"
                                    icon={<SpeedDialIcon />}
                                    onClose={handleClose}
                                    onOpen={handleOpen}
                                    open={open}
                                    sx={{
                                        position: 'absolute',
                                        bottom: 20,
                                        right: 20,
                                    }}
                                >
                                    {actions.map((action) => (
                                        <SpeedDialAction
                                            key={action.name}
                                            icon={action.icon}
                                            onClick={action.handleAction}
                                            tooltipTitle={action.name}
                                        />
                                    ))}
                                </SpeedDial>
                            </Box>
                        </div>
                        <div className="sketch-options-wrapper">
                            <div className="pallette-selectors">
                                <button
                                    className="sketch-item btn btn-primary"
                                    onClick={handleLineColorShow}
                                    style={{ height: '50px' }}
                                    type="button"
                                >
                                    Line Color
                                </button>

                                <button
                                    className="sketch-item btn btn-primary"
                                    onClick={handleBackColorShow}
                                    style={{ height: '50px' }}
                                    type="button"
                                >
                                    Background Color
                                </button>

                                <button
                                    className="sketch-item btn btn-primary"
                                    onClick={handleFillColorShow}
                                    style={{ height: '50px' }}
                                    type="button"
                                >
                                    Fill Color
                                </button>
                            </div>
                            <div className="set-flex">
                                <IconButton
                                    aria-label="Cirlce"
                                    color="primary"
                                    component="span"
                                >
                                    <CircleOutlinedIcon
                                        onClick={() =>
                                            setSelectedTool(Tools.Circle)
                                        }
                                    />
                                </IconButton>
                                <IconButton
                                    aria-label="Rectangle"
                                    color="primary"
                                    component="span"
                                >
                                    <RectangleOutlinedIcon
                                        onClick={() =>
                                            setSelectedTool(Tools.Rectangle)
                                        }
                                    />
                                </IconButton>
                                <IconButton
                                    aria-label="Line"
                                    color="primary"
                                    component="span"
                                >
                                    <HorizontalRuleIcon
                                        onClick={() =>
                                            setSelectedTool(Tools.Line)
                                        }
                                    />
                                </IconButton>
                            </div>
                            <div className="set-flex">
                                <IconButton
                                    aria-label="Select"
                                    color="primary"
                                    component="span"
                                >
                                    <MouseIcon
                                        onClick={() =>
                                            setSelectedTool(Tools.Select)
                                        }
                                    />
                                </IconButton>
                                <IconButton
                                    aria-label="Pan"
                                    color="primary"
                                    component="span"
                                >
                                    <PanToolIcon
                                        onClick={() =>
                                            setSelectedTool(Tools.Pan)
                                        }
                                    />
                                </IconButton>
                                <IconButton
                                    aria-label="Remove Selected"
                                    color="primary"
                                    component="span"
                                >
                                    <DeleteIcon
                                        onClick={() =>
                                            // eslint-disable-next-line no-underscore-dangle
                                            CourseHome._sketch.removeSelected()
                                        }
                                    />
                                </IconButton>
                            </div>
                        </div>
                        <div
                            className="sketch-item color-picker"
                            style={{
                                position: 'absolute',
                                bottom: '70px',
                                display: `${lineColorShow}`,
                            }}
                        >
                            <TwitterPicker
                                color={lineColor}
                                onChangeComplete={handleLineColorChange}
                                triangle="hide"
                            />
                        </div>
                        <div
                            className="sketch-item color-picker"
                            style={{
                                position: 'absolute',
                                bottom: '70px',
                                display: `${backColorShow}`,
                            }}
                        >
                            <TwitterPicker
                                color={backColor}
                                onChangeComplete={handleBackColorChange}
                                triangle="hide"
                            />
                        </div>

                        <div
                            className="sketch-item color-picker"
                            style={{
                                position: 'absolute',
                                bottom: '70px',
                                display: `${fillColorShow}`,
                            }}
                        >
                            <TwitterPicker
                                color={fillColor}
                                onChangeComplete={handleFillColorChange}
                                triangle="hide"
                            />
                        </div>
                    </>
                )
            case 3:
                return (
                    <MeetingDetails
                        courseId={courseId}
                        courseMeetingDetails={
                            currentCourseData.courseMeetingDetails
                        }
                    />
                )
            // case 7: return <div className='add-moderator-wrapper'>
            //     <div>
            //         <h5>List of Moderators</h5>
            //         <br></br>
            //         <div>
            //             {
            //                 moderatorsList?.length === 0 ? <>No moderators added yet. </> :
            //                     <List dense sx={{ width: '100%' }}>
            //                         {moderatorsList?.map((moderator, i) => {
            //                             return (<ListItem key={i} disablePadding>
            //                                 <ListItemButton>
            //                                     <ListItemAvatar>
            //                                         <Avatar
            //                                             alt={`Avatar n°${i + 1}`}
            //                                             src='/images/UserAvatarLogo.png'
            //                                         />
            //                                     </ListItemAvatar>
            //                                     <ListItemText primary={`${moderator.firstName} ${moderator.lastName} : ${moderator.email}`} />
            //                                 </ListItemButton>
            //                                 <Button onClick={() => handleDeleteModerator(moderator.email)}
            //                                     variant="outlined" startIcon={<DeleteIcon />}>
            //                                     Delete
            //                                 </Button>
            //                             </ListItem>)
            //                         }
            //                         )}
            //                     </List>
            //             }
            //         </div>
            //         <br></br>
            //     </div>
            //     <div className='row'>
            //         <div className={`col-md-4 ${isMobileOrTab ? 'mt-3' : ''}`}>
            //             <TextField
            //                 fullWidth
            //                 value={email}
            //                 autoFocus={userLoggedIn}
            //                 error={email.length !== 0 && !isValid}
            //                 onBlur={() => onEmailChange(email)}
            //                 id="email"
            //                 label="Email Id"
            //                 onChange={e => onEmailChange(e.target.value)}
            //                 maxLength={50}
            //             />
            //         </div>

            //         <button type="submit" disabled={userData.loading || !isValid} className={`btn btn-primary  ${isMobileOrTab ? 'mt-3 ml-3' : ''}`}
            //             onClick={() => handleAddModerator(email)}>Add Moderator</button>
            //         <div style={{ padding: '10px', color: 'red' }}>{message}</div>

            //     </div>
            // </div >
            default:
                return <h3>Please select an option</h3>
        }
    }
    return (
        <div className="container main-container mb-5">
            <div className="course-top-wrapper">
                <h3 className="col-md-8">{courseName}</h3>
                {/* {
                    isMobileOrTab &&
                    <CustomizedMenus name={"Options"} options={options} handleSelectedOption={handleSelectedOption} />
                } */}
            </div>

            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        aria-label="course home tabs"
                        onChange={(e, idx) => handleSelectedOption(idx)}
                        scrollButtons="auto"
                        value={selectedOption}
                        variant="scrollable"
                    >
                        {options.map((text) => (
                            <Tab key={text} label={text} />
                        ))}
                    </Tabs>
                </Box>
                <div className="render-wrapper">
                    {options.map((text, index) => (
                        <TabPanel
                            key={`tab-${text}`}
                            index={index}
                            value={selectedOption}
                        >
                            {renderTabContent(index)}
                        </TabPanel>
                    ))}
                </div>
                {/* <div className='render-wrapper'>
                {
                    renderSwitch()
                }
                </div> */}
            </Box>

            {/* <div className={`container display col-md-12 ${isMobileOrTab ? 'mt-3' : 'set-flex'}`} >
                {
                    !isMobileOrTab &&
                    (
                        <Box sx={{ boxShadow: '2px 2px 5px grey', border: "1px solid silver", borderRadius: "5px", marginRight: "15px", height: "fit-content" }}>
                            <List sx={{ width: '100%', }} >
                                {
                                    options.map((text, index) => (
                                        <ListItem onClick={() => handleSelectedOption(index)} key={text} disablePadding>
                                            <ListItemButton>
                                                <ListItemText primary={text} />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                            </List>
                        </Box>
                    )
                }
                <div className='containter render-wrapper'>
                    {
                        renderSwitch()
                    }
                </div>
            </div> */}
        </div>
    )
}

export default CourseHome
