import React, { useEffect, useCallback, useState } from 'react'
import CareerSearch from './forms/CareerSearch'
import MajorData from './Data.json'
import Metrics from '../utils/Metrics'
import JobSearch from './forms/JobSearch'
import CountryData from './CountryData.json'

function Careers() {
    useEffect(() => {
        window.scrollTo(0, 0)
        Metrics.event({
            category: 'page',
            action: 'view',
            label: 'careers',
        })
    }, [])

    const [selection, setSelection] = useState(null)

    const handleCallback = useCallback((value) => {
        setSelection(value.name)
    }, [])

    return (
        <div className="container main-container mt-4 mb-5">
            <div className="card" style={{ minHeight: '20vh' }}>
                <div className="card-body">
                    <h2 style={{ textAlign: 'center' }}>
                        Explore Career Options
                    </h2>
                    <CareerSearch
                        data={MajorData}
                        onSelection={handleCallback}
                    />
                </div>
                {selection !== null && selection.length !== 0 ? (
                    // <div className="card" style={{ minHeight: '80vh' }}>
                    <div className="card-body">
                        <JobSearch data={CountryData} search={selection} />
                    </div>
                ) : (
                    // </div>
                    ''
                )}
            </div>
            <ins
                className="adsbygoogle"
                data-ad-client="ca-pub-4879161983940680"
                data-ad-slot="983940680"
                style={{
                    display: 'inline-block',
                    minWidth: '100%',
                    minHeight: '20px',
                    height: '100%',
                }}
            />
        </div>
    )
}

export default Careers
