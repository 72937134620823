import React, { Fragment, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, NavLink } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import { Fab, Tooltip } from '@mui/material'
import { History } from '@mui/icons-material'
import TopLoader from '../commons/TopLoader'
import { fetchSavedCoursesMeta, deleteCourse } from '../../redux'
import '../../css/Card.css'
import '../../css/CourseHome.css'
import VerticalDotsMenu from '../commons/VerticalDotsMenu'

function CoursesList() {
    const dispatch = useDispatch()
    const userData = useSelector((state) => state.userData)
    const savedCourseData = useSelector((state) => state.savedCourseData)
    useEffect(() => {
        window.scrollTo(0, 0)
        if (
            userData.userFetchCompleted &&
            userData.user &&
            userData.user.id !== undefined
        ) {
            dispatch(fetchSavedCoursesMeta())
        }
    }, [dispatch, userData.userFetchCompleted, userData.user])

    if (userData && !userData.userFetchCompleted) {
        return <div className="container main-container mb-5" />
    }
    if (
        userData &&
        userData.userFetchCompleted &&
        (!userData.user || userData.user.id === undefined)
    ) {
        return <Redirect to="/" />
    }
    if (savedCourseData.loading) {
        return (
            <div className="container main-container mb-5">
                <TopLoader message={savedCourseData.loadingMessage} />
            </div>
        )
    }
    if (savedCourseData.error) {
        return (
            <div className="container main-container">
                <h4 className="alert alert-danger mt-4">
                    {savedCourseData.error}
                </h4>
            </div>
        )
    }
    if (savedCourseData.savedCoursesMeta.length === 0) {
        return (
            <div className="container main-container">
                <h4 className="alert alert-light mt-4">
                    No courses added. Go to&nbsp;
                    <NavLink to="/grade-analyzer">Grade Analyzer</NavLink> to
                    create a new course.
                </h4>
            </div>
        )
    }
    const handleDeleteGradesClick = (courseId) => {
        if (
            // eslint-disable-next-line no-alert
            window.confirm(
                'Are you sure you want to delete this saved course? This action is irreversible.'
            )
        ) {
            dispatch(deleteCourse(courseId))
            // TODO: add another redux action to handle this in savedCourse
            window.location.reload()
        }
    }
    const courseCards = savedCourseData.savedCoursesMeta.map(
        (savedCourseMeta) => {
            const { id, name, lastUpdatedOn } = savedCourseMeta
            return (
                <div key={id} className="basic-card card">
                    <div className="card-body meta-container">
                        <div
                            className="container"
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <h5 className="card-title">{name}</h5>
                            <VerticalDotsMenu
                                onClick={() => handleDeleteGradesClick(id)}
                            />
                        </div>
                        <div className="row ml-1">
                            <History style={{ alignSelf: 'center' }} />
                            <p className="card-text m-2">
                                {new Date(lastUpdatedOn).toLocaleString()}
                            </p>
                        </div>
                        {/* <a href={`/course/${encodeURI(id)}`} className="btn btn-primary">Open</a> */}
                        <a
                            className="btn btn-primary"
                            href={`/course/home/${encodeURI(id)}`}
                        >
                            Open
                        </a>
                    </div>
                </div>
            )
        }
    )
    return (
        <>
            <div className="container course-top-wrapper">
                <h1 className="container">Courses</h1>

                <NavLink to="/grade-analyzer">
                    <Fab aria-label="add" color="primary">
                        <Tooltip title="Add Course">
                            <AddIcon />
                        </Tooltip>
                    </Fab>
                </NavLink>
            </div>
            <div
                className="container main-container mt-4 mb-5 row"
                style={{ margin: 'auto' }}
            >
                {courseCards}
            </div>
        </>
    )
}

export default CoursesList
