import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'
import TopLoader from '../commons/TopLoader'
import { fetchQuizMetas } from '../../redux'
import '../../css/Card.css'

function QuizList() {
    const dispatch = useDispatch()
    const userData = useSelector((state) => state.userData)
    const quizMetasData = useSelector((state) => state.quizMetaData)
    const { category: quizCategory } = useParams()
    useEffect(() => {
        window.scrollTo(0, 0)
        if (
            userData.userFetchCompleted &&
            userData.user &&
            userData.user.id !== undefined
        ) {
            dispatch(fetchQuizMetas(quizCategory))
        }
    }, [dispatch, userData.userFetchCompleted, userData.user, quizCategory])
    if (userData && !userData.userFetchCompleted) {
        return <div className="container main-container mb-5" />
    }
    if (
        userData &&
        userData.userFetchCompleted &&
        (!userData.user || userData.user.id === undefined)
    ) {
        return <Redirect to="/" />
    }
    if (quizMetasData.loading) {
        return (
            <div className="container main-container mb-5">
                <TopLoader message={quizMetasData.loadingMessage} />
            </div>
        )
    }
    if (quizMetasData.error) {
        return (
            <div className="container main-container">
                <h4 className="alert alert-danger mt-4">
                    {quizMetasData.error}
                </h4>
            </div>
        )
    }
    if (quizMetasData.quizMetas.length === 0) {
        return (
            <div className="container main-container">
                <h4 className="alert alert-light mt-4">
                    No quiz available currently.
                </h4>
            </div>
        )
    }

    const quizCards = quizMetasData.quizMetas.map((quizMeta) => {
        const { title, synopsis } = quizMeta
        const synopsisTrimmed =
            synopsis.length > 83
                ? `${synopsis.substring(0, 80).trim()}...`
                : synopsis
        return (
            <div key={title.substring(0, 10)} className="basic-card card">
                {/* <img class="card-img-top" src="..." alt="Card image cap"/> */}
                <div className="card-body meta-container">
                    <h5 className="card-title">{title}</h5>
                    <p className="card-text">{synopsisTrimmed}</p>
                    <a
                        className="btn btn-primary"
                        href={`/quiz/${quizMeta.id}`}
                    >
                        Take the quiz
                    </a>
                </div>
            </div>
        )
    })
    return (
        <div
            className="container main-container mt-4 mb-5 row"
            style={{ margin: 'auto' }}
        >
            {quizCards}
        </div>
    )
}

export default QuizList
