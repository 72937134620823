export const shuffleAnswerOptionsForQuestion = (question) => {
    /* eslint-disable no-param-reassign */
    let currentIndex = question.answers.length - 1
    let randomIndex
    while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * (currentIndex + 1))
        if (question.correctAnswer === (currentIndex + 1).toString()) {
            question.correctAnswer = (randomIndex + 1).toString()
        } else if (question.correctAnswer === (randomIndex + 1).toString()) {
            question.correctAnswer = (currentIndex + 1).toString()
        }
        ;[question.answers[randomIndex], question.answers[currentIndex]] = [
            question.answers[currentIndex],
            question.answers[randomIndex],
        ]
        currentIndex -= 1
    }
}

export const shuffleAnswerOptionsForAllQuestions = (questions) => {
    questions.forEach((question) => {
        shuffleAnswerOptionsForQuestion(question)
    })
}
