import React from 'react'
import PropTypes from 'prop-types'
import InlineLoader from './InlineLoader'

function TopLoader({ message }) {
    return (
        <div className="loader-container">
            <InlineLoader message={message} />
        </div>
    )
}

TopLoader.propTypes = {
    message: PropTypes.string.isRequired,
}

export default TopLoader
