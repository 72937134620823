import * as React from 'react';
import PropTypes from 'prop-types'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

const DEFAULT_ROWS_PER_PAGE = 10;

function JobsTable({data}) {
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [visibleRows, setVisibleRows] = React.useState(null);
  const [rowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);
  const [paddingHeight, setPaddingHeight] = React.useState(0);

  React.useEffect(() => {
    let rowsOnMount = data;
    rowsOnMount = rowsOnMount.slice(
      0 * DEFAULT_ROWS_PER_PAGE,
      0 * DEFAULT_ROWS_PER_PAGE + DEFAULT_ROWS_PER_PAGE,
    );

    setVisibleRows(rowsOnMount);
  }, []);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = React.useCallback(
    (event, newPage) => {
      setPage(newPage);

      const sortedRows = data
      const updatedRows = sortedRows.slice(
        newPage * rowsPerPage,
        newPage * rowsPerPage + rowsPerPage,
      );

      setVisibleRows(updatedRows);

      // Avoid a layout jump when reaching the last page with empty rows.
      const numEmptyRows =
        newPage > 0 ? Math.max(0, (1 + newPage) * rowsPerPage - data.length) : 0;

      const newPaddingHeight = (33) * numEmptyRows;
      setPaddingHeight(newPaddingHeight);
    },
    [rowsPerPage],
  );

  return (
    <div>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="small"
            style={{marginLeft:"25px"}}
          >
            <TableBody>
              {visibleRows
                ? visibleRows.map((job) => {
                    return (
                      <TableRow
                        onClick={(event) => handleClick(event, job.title)}
                        role="checkbox"
                        tabIndex={-1}
                        key={job.id}
                        sx={{ cursor: 'pointer' }}
                        style={{ width: "100%" }}
                      >
                        <TableCell>
                        <div key={job.id} className="card" style={{marginBottom:"15px", maxWidth: '850px'}}>
                                <div className="card-body">
                                    {job.salary_max != null ? (
                                        <h4 className="card-title">
                                            {job.title} upto ${job.salary_max}
                                        </h4>
                                    ) : (
                                        <h4 className="card-title">
                                            {job.title}
                                        </h4>
                                    )}
                                    <h5>{job.company.display_name}</h5>
                                    <h5>{job.location.display_name}</h5>
                                    <p className="card-text">
                                        {job.description}
                                    </p>
                                    <a
                                        href={job.redirect_url}
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        View Job
                                    </a>
                                </div>
                            </div>
                        </TableCell>
                      </TableRow>
                    );
                  })
                : null}
              {paddingHeight > 0 && (
                <TableRow
                  style={{
                    height: paddingHeight,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[10]}
        />
    </div>
  );
}

JobsTable.propTypes = {
  // data: PropTypes.arrayOf(PropTypes.shape({
  //   title: PropTypes.string,
  //   salary_max: PropTypes.number,
  //   description: PropTypes.string,
  //   redirect_url: PropTypes.string
  // })).isRequired
  
  // eslint-disable-next-line no-undef
  data: PropTypes.arrayOf(PropTypes.object).isRequired 
}

export default JobsTable