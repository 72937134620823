import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'

function TabPanel({ children, value, index, ...other }) {
    if (value !== index) {
        return null
    }
    return (
        <div
            aria-labelledby={`simple-tab-${index}`}
            id={`simple-tabpanel-${index}`}
            role="tabpanel"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...other}
        >
            <Box>{children}</Box>
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.element,
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
}

TabPanel.defaultProps = {
    children: null,
}

export default TabPanel
