import axios from 'axios'
import {
    QUIZ_META_FAILURE,
    QUIZ_META_REQUEST,
    QUIZ_META_SUCCESS,
} from './quizMetaTypes'

export const quizMetaRequest = () => ({
    type: QUIZ_META_REQUEST,
})

export const quizMetaSuccess = (quizMetas) => ({
    type: QUIZ_META_SUCCESS,
    payload: quizMetas,
})

export const quizMetaFailure = (errorMsg) => ({
    type: QUIZ_META_FAILURE,
    payload: errorMsg,
})

export const fetchQuizMetas = (category) => (dispatch) => {
    dispatch(quizMetaRequest())
    let url = `${process.env.REACT_APP_BASE_URL}/quiz/meta`
    if (category && category.trim().length > 0) {
        url += `?category=${category}`
    }
    axios
        .get(url)
        .then((res) => {
            const quizMetas = res.data
            if (!quizMetas) {
                dispatch(quizMetaFailure('No quiz available'))
            } else {
                // quizMetas.sort((a, b) => (a.title > b.title ? 1 : -1))
                quizMetas.sort((a, b) => {
                    // Extract the quiz name and number from the title
                    const regex = /(.+) - Quiz (\d+)/;
                    const aMatch = a.title.match(regex);
                    const bMatch = b.title.match(regex);
                    const aName = aMatch[1];
                    const bName = bMatch[1];
                    const aNumber = parseInt(aMatch[2]);
                    const bNumber = parseInt(bMatch[2]);
                    
                    // Compare the quiz names first
                    if (aName < bName) {
                      return -1;
                    }
                    if (aName > bName) {
                      return 1;
                    }
                    
                    // If the quiz names are the same, compare the quiz numbers
                    return aNumber - bNumber;
                  });
                  
                dispatch(quizMetaSuccess(quizMetas))
            }
        })
        .catch((err) => {
            dispatch(
                quizMetaFailure(
                    (err.response && err.response.data) ||
                        'Error fetching available quizzes'
                )
            )
        })
}
