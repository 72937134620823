import {
    React,
} from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import {
    TextField, 
    Grid, 
    IconButton, 
    MenuItem,
    Select,
} from '@mui/material' 
import DeleteIcon from '@mui/icons-material/Delete'
import PropTypes from 'prop-types'
import { findLetterGrade, findLetterGradeCutoff, getLetterGrades } from './gpaToLetterGrade'

function CourseRow({
    course,
    onDelete,
    onCourseNameChange,
    onCourseGpaChange,
    onCourseCreditHoursChange
}){

  return (
    <TableRow>
        <TableCell align="center">
            <Grid
                alignItems="center"
                container
                minWidth="130px"
                spacing={2}
            >
                <Grid item lg={9} md={9} xl={9} xs={9.5} alignItems="center">
                    <TextField
                        inputProps={{ style: { minWidth: '100px' } }}
                        size="small"
                        variant="standard"
                        value = {course.name}
                        onChange = {(e) => onCourseNameChange(course.id, e.target.value)}
                    />
                </Grid>
            </Grid>
        </TableCell>
        <TableCell  align="center">
            <TextField
                inputProps={{
                    type: 'number',
                    size: 'small',
                    min: 0,
                    max: 4,
                    step: 1,
                    style: { textAlign: 'center' },
                }}
                value = {course.creditHours}
                onChange = {(e) => onCourseCreditHoursChange(course.id, e.target.value)}
                onWheel={(e) => e.target.blur()}
                variant="standard"
            />
        </TableCell>
        <TableCell  align="center">
            <Select
                fullWidth
                onChange = {(e) => onCourseGpaChange(course.id, findLetterGradeCutoff(e.target.value))}
                value = {course.grade ? findLetterGrade(course.grade) : findLetterGrade(0)}
                variant="standard"
            >
                {
                    getLetterGrades().map((item) => {
                        return <MenuItem key = {item} value={item}>{item}</MenuItem>
                    })
                }
            </Select>
        </TableCell>
        <TableCell  align="center">
            <IconButton
                aria-label="delete item"
                edge="end"
                onClick = {() => onDelete(course)}
            >
                <DeleteIcon />
            </IconButton>
        </TableCell>
    </TableRow>
  )
}

export default CourseRow

CourseRow.propTypes = {
    course: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        grade: PropTypes.number,
        creditHours: PropTypes.number,
    }).isRequired,
    onDelete: PropTypes.func.isRequired,
    onCourseNameChange: PropTypes.func.isRequired,
    onCourseGpaChange: PropTypes.func.isRequired,
    onCourseCreditHoursChange: PropTypes.func.isRequired,
}
