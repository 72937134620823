import {
    QUIZ_CLEAR,
    QUIZ_FAILURE,
    QUIZ_REQUEST,
    QUIZ_SUCCESS,
} from './quizTypes'

const initialState = {
    loading: false,
    loadingMessage: '',
    attemptedFetch: false,
    fetchSuccess: false,
    quiz: null,
    message: '',
    error: '',
}

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case QUIZ_REQUEST:
            return {
                ...state,
                loading: true,
                loadingMessage: 'Loading the quiz...',
                attemptedFetch: true,
                fetchSuccess: false,
                quiz: null,
                message: '',
                error: '',
            }
        case QUIZ_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                fetchSuccess: true,
                quiz: action.payload,
                message: '',
                error: '',
            }
        case QUIZ_FAILURE:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                fetchSuccess: false,
                quiz: null,
                message: '',
                error: action.payload,
            }
        case QUIZ_CLEAR:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                attemptedFetch: false,
                fetchSuccess: false,
                quiz: null,
                message: '',
                error: '',
            }
        default:
            return state
    }
}

export default reducer
