import React from 'react'
import PropTypes from 'prop-types'

function InlineLoader({ message }) {
    return (
        <div className="row">
            <div className="loader" />
            {message ? <div className="loader-message">{message}</div> : null}
        </div>
    )
}

InlineLoader.propTypes = {
    message: PropTypes.string,
}

InlineLoader.defaultProps = {
    message: '',
}
export default InlineLoader
