/* eslint-disable react/jsx-no-bind */
import {
    React,
    useState,
} from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import {
    Paper, 
    TextField, 
    IconButton, 
    Typography,
    Tooltip,
} from '@mui/material'

import Box from '@mui/material/Box'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import {
    tableHeaderCellSecondaryTheme,
    tableHeaderRowSecondaryTheme,
    tableFooterTheme,
} from './GPATable/Theme'
import CourseRow from './GPATable/CourseRow'

function GPAAnalyzer() {
    
    const [courses, setCourses] = useState([])
    const [nextId, setNextId] = useState(0)

    const [currentGpa, setCurrentGpa] = useState(0)
    const [targetGpa, setTargetGpa] = useState(0)
    const [remCreditHours, setRemCreditHours] = useState(0)
    const [totalCreditHours, setTotalCreditHours] = useState(0)
    const [tooltipIsOpen, setTooltipIsOpen] = useState(false)

    const COURSE_CREDIT_HOURS_DEFAULT = 3
    const letterGradeToGpa = "\nA+/A: 4\nA-: 3.7\nB+: 3.3\nB: 3\nB-: 2.7\nC+: 2.3\nC: 2\nC-: 1.7\nD+: 1.3\nD: 1\nD-: 0.7\nF: 0"
    const addCourse = function() {
        const course = {id: nextId, name: `Course ${nextId+1}`, grade: 0.0, creditHours: COURSE_CREDIT_HOURS_DEFAULT}
        const coursesList = [...courses];
        coursesList.push(course)
        setCourses(coursesList)
        setNextId(nextId+1)
    }

    const deleteCourse = function(course) {
        const coursesList = courses.filter((courseItem) => (courseItem.id !== course.id))
        setCourses(coursesList)
    }

    const onCourseGpaChange = function(courseId, newGrade) {
        const gradeNum = Number(newGrade)
        const coursesList = courses.map((course) => {
            if (course.id === courseId) {
              return {
                ...course,
                grade: gradeNum
              };
            }
            return course;
        });
        setCourses(coursesList)
    }

    const onCourseNameChange = function(courseId, newName) {
        const coursesList = courses.map((course) => {
            if (course.id === courseId) {
              return {
                ...course,
                name: newName
              };
            }
            return course;
        });
        setCourses(coursesList)
    }

    const onCourseCreditHoursChange = function(courseId, newCreditHours) {
        const newCreditHoursNum = Number(newCreditHours)
        const coursesList = courses.map((course) => {
            if (course.id === courseId) {
              return {
                ...course,
                creditHours: newCreditHoursNum
              };
            }
            return course;
        });
        setCourses(coursesList)
    }

    const calculateTotalHoursEntered = function() {
        let totalCreditHoursEntered = 0
        courses.forEach((course) => {
            totalCreditHoursEntered += course.creditHours
        })
        return totalCreditHoursEntered
    }

    const calculateAverageCourseGpas = function() {
        const totalHoursEntered = calculateTotalHoursEntered()
        if (totalHoursEntered !== remCreditHours) {
            window.alert('Total credit hours of courses entered should be equal to remaining credit hours')
            return
        }

        const creditsCompleted = totalCreditHours - remCreditHours
        const currentPoints = currentGpa * creditsCompleted
        const targetPoints = targetGpa * totalCreditHours
        let avgCourseGpa = (targetPoints - currentPoints)/remCreditHours
        avgCourseGpa = Math.round(avgCourseGpa * 100) / 100
        const modifiedCourseList = courses.map((course) => {
            return {...course, grade: Math.min(avgCourseGpa, 4.0)}
        })
        setCourses(modifiedCourseList)
    }

    const calculateFinalGpa = function() {
        const creditsCompleted = totalCreditHours - remCreditHours
        const currentPoints = currentGpa * creditsCompleted
        let finalPoints = currentPoints
        courses.forEach((course) => {
            finalPoints += (course.grade * course.creditHours)
        })
        const finalGpa =  Math.round(finalPoints/totalCreditHours * 100) / 100
        return Number.isNaN(finalGpa) ? '' : finalGpa
    }

    return (
        <div className="container main-container mb-5">
            <div className="col-md-10">
                <div className="row">
                    <div className="col-md-2 mt-3">
                        <TextField
                            id="current_gpa"
                            label="Current GPA"
                            onChange = {(e) => setCurrentGpa(Number(e.target.value))}
                        />
                    </div>
                    <div className="col-md-2 mt-3">
                        <TextField
                            id="target_gpa"
                            label="Target GPA"
                            onChange = {(e) => setTargetGpa(Number(e.target.value))}
                        />
                    </div>
                    <div className="col-md-2 mt-3">
                        <TextField
                            id="remaining_credit_hours"
                            label="Remaining credit hours"
                            onChange = {(e) => setRemCreditHours(Number(e.target.value))}
                        />
                    </div>
                    <div className="col-md-2 mt-3">
                        <TextField
                            id="total_credit_hours"
                            label="Total credit hours"
                            onChange = {(e) => setTotalCreditHours(Number(e.target.value))}
                        />
                    </div>
                </div>
            </div>
            <div className="mt-4">
                <div className="col-md-12">
                    <span>
                        <b>
                            Courses needed to graduate:
                        </b>
                    </span>
                </div>
                <TableContainer component={Paper} style = {{width: '60%'}}>
                    <Box sx={{ margin: 0.5 }}>
                        <Table aria-label=" courses table" size="small">
                            <colgroup>
                                <col style={{ width: '30%' }} />
                                <col style={{ width: '30%' }} />
                                <col style={{ width: '30%' }} />
                                <col style={{ width: '5%' }} />
                            </colgroup>
                            <TableHead>
                                <TableRow sx={tableHeaderRowSecondaryTheme}>
                                    <TableCell
                                        align="center"
                                        sx={tableHeaderCellSecondaryTheme}
                                    >
                                        Name
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        sx={tableHeaderCellSecondaryTheme}
                                    >
                                        Credit Hours
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        sx={tableHeaderCellSecondaryTheme}
                                    >
                                        Letter Grade
                                        <Tooltip
                                            className="ml-1"
                                            onClick={() =>
                                                setTooltipIsOpen(true)
                                            }
                                            onMouseOut={() =>
                                                setTooltipIsOpen(false)
                                            }
                                            onOpen={() =>
                                                setTooltipIsOpen(true)
                                            }
                                            open={tooltipIsOpen}
                                            sx={{ paddingBottom: '2px' }}
                                            title={`Following are the letter grade to GPA mappings: ${letterGradeToGpa}`}
                                        >
                                            <InfoOutlinedIcon fontSize="small" />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    courses.map((courseItem) => {
                                        return <CourseRow 
                                        key = {courseItem.id}
                                        course = {courseItem} 
                                        onDelete = {deleteCourse} 
                                        onCourseNameChange = {onCourseNameChange}
                                        onCourseGpaChange={onCourseGpaChange}
                                        onCourseCreditHoursChange = {onCourseCreditHoursChange}
                                        />
                                    })
                                }
                            </TableBody>
                            
                        </Table>
                        <Table aria-label="footer" size="small">
                            <TableBody>
                                <TableRow sx={tableFooterTheme}>
                                    <TableCell
                                        colSpan={2}
                                        size="small"
                                        width = "30%"
                                    >
                                        <IconButton
                                            aria-label="add a course"
                                            edge="end"
                                            size="small"
                                            onClick={() => addCourse()}
                                        >
                                            <AddCircleOutlineIcon />
                                            <Typography marginLeft="12px">
                                                Add a course
                                            </Typography>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell
                                        align = "center"
                                        colSpan={2}
                                        width="28%"
                                    >
                                        <Typography
                                        >
                                            <b>{calculateTotalHoursEntered()}</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell 
                                    colSpan={2} 
                                    width="32%"
                                    >
                                        <Typography 
                                        align = "center"
                                        >
                                            <b>Final: {calculateFinalGpa()}</b>
                                            {
                                                calculateFinalGpa() < targetGpa ? <span>Target grade cannot be achieved</span> : null
                                            }
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        className="container"
                                        colSpan={2}
                                        width="5%"
                                        />
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Box>
                </TableContainer>
                <button
                    className="btn btn-warning m-2"
                    type="submit"
                    onClick={() => {
                        calculateAverageCourseGpas()
                    }}
                >
                    Achieve Target GPA
                </button>
            </div>
        </div>
    )
}

export default GPAAnalyzer