import axios from 'axios'
import {
    GET_CURRENT_USER_FAILURE,
    GET_CURRENT_USER_REQUEST,
    GET_CURRENT_USER_SUCCESS,
    USER_LOGIN_FAILURE,
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT_FAILURE,
    USER_LOGOUT_REQUEST,
    USER_LOGOUT_SUCCESS,
    USER_REGISTER_EXIT,
    USER_REGISTER_FAILURE,
    USER_REGISTER_INIT,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
} from './userTypes'

export const userLoginRequest = () => ({
    type: USER_LOGIN_REQUEST,
})

export const userLoginSuccess = () => ({
    type: USER_LOGIN_SUCCESS,
})

export const userLoginFailure = (errorMsg) => ({
    type: USER_LOGIN_FAILURE,
    payload: errorMsg,
})

export const userRegisterInit = () => ({
    type: USER_REGISTER_INIT,
})

export const userRegisterRequest = () => ({
    type: USER_REGISTER_REQUEST,
})

export const userRegisterSuccess = () => ({
    type: USER_REGISTER_SUCCESS,
})

export const userRegisterFailure = (errorMsg) => ({
    type: USER_REGISTER_FAILURE,
    payload: errorMsg,
})

export const userRegisterExit = () => ({
    type: USER_REGISTER_EXIT,
})

export const getUserRequest = () => ({
    type: GET_CURRENT_USER_REQUEST,
})

export const getUserSuccess = (user) => ({
    type: GET_CURRENT_USER_SUCCESS,
    payload: user,
})

export const getUserFailure = (errorMsg) => ({
    type: GET_CURRENT_USER_FAILURE,
    payload: errorMsg,
})

export const userLogoutRequest = () => ({
    type: USER_LOGOUT_REQUEST,
})

export const userLogoutSuccess = () => ({
    type: USER_LOGOUT_SUCCESS,
})

export const userLogoutFailure = () => ({
    type: USER_LOGOUT_FAILURE,
})

export const login = (email, password) => (dispatch) => {
    dispatch(userLoginRequest())
    axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/auth/login`,
        data: {
            email,
            password,
        },
        headers: { 'Content-Type': 'application/json' },
    })
        .then(() => {
            dispatch(userLoginSuccess())
        })
        .catch((error) => {
            dispatch(
                userLoginFailure(
                    (error.response && error.response.data) || 'Login failed'
                )
            )
        })
}

export const googleLogin = (jwtToken) => (dispatch) => {
    dispatch(userLoginRequest())
    axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/auth/google-login`,
        data: {
            jwtToken,
        },
        headers: { 'Content-Type': 'application/json' },
    })
        .then(() => {
            dispatch(userLoginSuccess())
        })
        .catch((error) => {
            dispatch(
                userLoginFailure(
                    (error.response && error.response.data) || 'Login failed'
                )
            )
        })
}

export const register =
    (firstName, lastName, email, password) => (dispatch) => {
        dispatch(userRegisterRequest())
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}/auth/register`,
            data: {
                firstName,
                lastName,
                email,
                password,
            },
            headers: { 'Content-Type': 'application/json' },
        })
            .then(() => {
                dispatch(userRegisterSuccess())
            })
            .catch((error) => {
                dispatch(
                    userRegisterFailure(
                        (error.response && error.response.data) ||
                            'Registration failed'
                    )
                )
            })
    }

export const logout = () => (dispatch) => {
    dispatch(userLogoutRequest())
    axios
        .get(`${process.env.REACT_APP_BASE_URL}/auth/logout`)
        .then(() => {
            dispatch(userLogoutSuccess())
        })
        .catch(() => {
            dispatch(userLogoutFailure())
        })
}

export const getCurrentUser = () => (dispatch) => {
    dispatch(getUserRequest())
    axios
        .get(`${process.env.REACT_APP_BASE_URL}/user`)
        .then((response) => {
            const user = response.data
            if (!user || user === {} || user.id === undefined) {
                dispatch(getUserFailure())
                dispatch(logout())
            } else {
                dispatch(getUserSuccess(user))
            }
        })
        .catch((error) => {
            dispatch(getUserFailure())
            if (!error.response || error.response.status !== 401) {
                dispatch(logout())
            }
        })
}
