import React, { Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import getQueryStringParams from '../utils/queryParams'
import TopLoader from './commons/TopLoader'
import ResetPasswordForm from './forms/ResetPasswordForm'

function ResetPassword(props) {
    const userData = useSelector((state) => state.userData)
    const resetPasswordData = useSelector((state) => state.resetPasswordData)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    if (
        userData.userFetchCompleted &&
        userData.user &&
        userData.user.id !== undefined
    ) {
        return <Redirect to="/" />
    }
    const {
        // eslint-disable-next-line react/prop-types
        location: { search: searchParams },
    } = props
    const queryParams = getQueryStringParams(searchParams)
    const token = queryParams.token || ''

    let loadingMessage = null
    if (userData.loading) {
        loadingMessage = userData.loadingMessage
    } else if (resetPasswordData.loading) {
        loadingMessage = resetPasswordData.loadingMessage
    }

    return (
        <>
            {userData.loading ? <TopLoader message={loadingMessage} /> : null}

            <div className="container main-container mt-4 mb-5">
                <div className="card">
                    <div className="card-body">
                        <ResetPasswordForm token={token} />
                    </div>
                </div>
                {resetPasswordData.error ? (
                    <h4 className="alert alert-danger mt-4">
                        {resetPasswordData.error}
                    </h4>
                ) : (
                    ''
                )}
                {resetPasswordData.message ? (
                    <h4 className="alert alert-success mt-4">
                        {resetPasswordData.message}
                    </h4>
                ) : (
                    ''
                )}
            </div>
        </>
    )
}

export default ResetPassword
