import React, { Fragment, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import TopLoader from '../commons/TopLoader'
import { fetchQuizCategoryMetas } from '../../redux'
import '../../css/Card.css'

function QuizCategoryList() {
    const dispatch = useDispatch()
    const userData = useSelector((state) => state.userData)
    const quizCategoryMetaData = useSelector(
        (state) => state.quizCategoryMetaData
    )
    useEffect(() => {
        window.scrollTo(0, 0)
        if (
            userData.userFetchCompleted &&
            userData.user &&
            userData.user.id !== undefined
        ) {
            dispatch(fetchQuizCategoryMetas())
        }
    }, [dispatch, userData.userFetchCompleted, userData.user])
    if (userData && !userData.userFetchCompleted) {
        return <div className="container main-container mb-5" />
    }
    if (
        userData &&
        userData.userFetchCompleted &&
        (!userData.user || userData.user.id === undefined)
    ) {
        return <Redirect to="/" />
    }
    if (quizCategoryMetaData.loading) {
        return (
            <div className="container main-container mb-5">
                <TopLoader message={quizCategoryMetaData.loadingMessage} />
            </div>
        )
    }
    if (quizCategoryMetaData.error) {
        return (
            <div className="container main-container">
                <h4 className="alert alert-danger mt-4">
                    {quizCategoryMetaData.error}
                </h4>
            </div>
        )
    }
    if (quizCategoryMetaData.quizCategoryMetas.length === 0) {
        return (
            <div className="container main-container">
                <h4 className="alert alert-light mt-4">
                    No quiz available currently.
                </h4>
            </div>
        )
    }

    const categoryCards = Object.keys(
        quizCategoryMetaData.quizCategoryMetas
    ).map((quizCategory) => {
        const { quizCount } =
            quizCategoryMetaData.quizCategoryMetas[quizCategory]
        return (
            <div key={quizCategory} className="basic-card card">
                {/* <img class="card-img-top" src="..." alt="Card image cap"/> */}
                <div className="card-body meta-container">
                    <h5 className="card-title">{quizCategory}</h5>
                    <p className="card-text">{`${quizCount} quiz${
                        quizCount > 1 ? 'zes' : ''
                    }`}</p>
                    <a
                        className="btn btn-primary"
                        href={`/quizzes/${encodeURI(quizCategory)}`}
                    >
                        Explore
                    </a>
                </div>
            </div>
        )
    })
    return (
        <>
            <h1 className="container">Quizzes</h1>
            <div
                className="container main-container mt-4 mb-5 row"
                style={{ margin: 'auto' }}
            >
                {categoryCards}
            </div>
        </>
    )
}

export default QuizCategoryList
