import React from 'react'
import PropTypes from 'prop-types'
import { Autocomplete, TextField } from '@mui/material'
import { matchSorter } from 'match-sorter'

// const getURLFromOriginalName = (originalName) => {
//     const subUrl = originalName
//         .toLowerCase()
//         .replaceAll(',', '')
//         .replaceAll('/', '-')
//         .replaceAll(' ', '-')
//     return `https://www.zippia.com/widgets/college-major-paths/${subUrl}-major`
// }

function CareerSearch({ data, onSelection }) {
    // const [selectedOption, setSelectedOption] = useState(null)

    const filterOptions = (options, { inputValue }) =>
        matchSorter(options, inputValue, { keys: ['display_name'] })

    const onChange = (event, value) => {
        // setSelectedOption(value)
        onSelection(value)
    }

    return (
        <div style={{ width: '100%', display: 'block', textAlign: 'center' }}>
            <div className="search-container">
                <Autocomplete
                    autoHighlight
                    filterOptions={filterOptions}
                    getOptionLabel={(option) => option.display_name}
                    id="combo-box-demo"
                    onChange={onChange}
                    options={data}
                    renderInput={(params) => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <TextField {...params} label="Enter your major" />
                    )}
                />
            </div>
            {/* {selectedOption && (
                <div style={{ maxWidth: '768px', margin: '30px 15px' }}>
                    <div
                        style={{
                            position: 'relative',
                            paddingTop: '580px',
                            left: '20%',
                        }}
                    >
                        <iframe
                            className="career-container"
                            frameBorder="no"
                            src={getURLFromOriginalName(
                                selectedOption.original_name
                            )}
                            style={{
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '100%',
                                height: '100%',
                            }}
                            title="Careers"
                        />
                    </div>
                </div>
            )} */}
        </div>
    )
}

CareerSearch.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.array,
    onSelection: PropTypes.func,
}

CareerSearch.defaultProps = {
    data: [],
    onSelection: null,
}

export default CareerSearch
