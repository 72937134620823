import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    clearErrorForKey,
    setErrorForKey,
    sendContactUsEmail,
} from '../../redux'
import { validateEmail } from '../../utils'

function ContactUsForm() {
    const dispatch = useDispatch()
    const userData = useSelector((state) => state.userData)
    const contactUsData = useSelector((state) => state.contactUsData)
    const errorData = useSelector((state) => state.errorData)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    useEffect(
        () => () => {
            dispatch(clearErrorForKey('name'))
            dispatch(clearErrorForKey('email'))
        },
        [dispatch]
    )

    const onNameChange = (updatedName) => {
        let errorMessage = null
        if (updatedName.trim().length < 1) errorMessage = 'Invalid name'
        dispatch(setErrorForKey('name', errorMessage))
        setName(updatedName)
    }

    const onEmailChange = (updatedEmail) => {
        let errorMessage = null
        if (!validateEmail(updatedEmail))
            errorMessage = 'Invalid email provided'
        dispatch(setErrorForKey('email', errorMessage))
        setEmail(updatedEmail)
    }

    const isFormValid = () =>
        errorData.name === null && errorData.email === null

    let nameInputClassNames = 'form-control input-max-400'
    if (errorData.email !== undefined) {
        if (errorData.email) {
            nameInputClassNames += ' form-input-invalid'
        } else {
            nameInputClassNames += ' form-input-valid'
        }
    }

    let emailInputClassNames = 'form-control input-max-400'
    if (errorData.email !== undefined) {
        if (errorData.email) {
            emailInputClassNames += ' form-input-invalid'
        } else {
            emailInputClassNames += ' form-input-valid'
        }
    }

    return (
        <form>
            <div className="row" style={{ margin: 0 }}>
                <div className="form-group">
                    <label className="input-max-400" htmlFor="text">
                        Name
                        <input
                            className={nameInputClassNames}
                            id="name"
                            maxLength={50}
                            onChange={(e) => onNameChange(e.target.value)}
                            type="text"
                            value={name}
                        />
                    </label>
                </div>
                <div className="form-group">
                    <label className="input-max-400" htmlFor="email">
                        Email
                        <input
                            className={emailInputClassNames}
                            id="email"
                            maxLength={50}
                            onChange={(e) => onEmailChange(e.target.value)}
                            type="email"
                            value={email}
                        />
                    </label>
                </div>
            </div>
            <div className="row" style={{ margin: 0 }}>
                <div className="form-group">
                    <label
                        htmlFor="message"
                        style={{ width: '100%', maxWidth: '930px' }}
                    >
                        Message
                        <textarea
                            className="form-control"
                            id="message"
                            maxLength={1000}
                            onChange={(e) => setMessage(e.target.value)}
                            value={message}
                        />
                    </label>
                </div>
            </div>
            <button
                className="btn btn-primary"
                disabled={
                    userData.loading || contactUsData.loading || !isFormValid()
                }
                onClick={() =>
                    dispatch(sendContactUsEmail(name, email, message))
                }
                type="submit"
            >
                Send
            </button>
        </form>
    )
}

export default ContactUsForm
