import {
    QUIZ_CATEGORY_META_FAILURE,
    QUIZ_CATEGORY_META_REQUEST,
    QUIZ_CATEGORY_META_SUCCESS,
} from './quizCategoryTypes'

const initialState = {
    loading: false,
    loadingMessage: '',
    fetchSuccess: false,
    quizCategoryMetas: [],
    message: '',
    error: '',
}

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case QUIZ_CATEGORY_META_REQUEST:
            return {
                ...state,
                loading: true,
                loadingMessage: 'Fetching available quiz categories...',
                fetchSuccess: false,
                quizCategoryMetas: {},
                message: '',
                error: '',
            }
        case QUIZ_CATEGORY_META_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                fetchSuccess: true,
                quizCategoryMetas: action.payload,
                message: '',
                error: '',
            }
        case QUIZ_CATEGORY_META_FAILURE:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                fetchSuccess: false,
                quizCategoryMetas: {},
                message: '',
                error: action.payload,
            }
        default:
            return state
    }
}

export default reducer
