import { roundValueToDecimals } from '../../../utils'
import {
    CATEGORY_WEIGHT_DECIMAL_ACCURACY,
    POINTS_DECIMAL_ACCURACY,
} from '../../../utils/constants'

export const getUpdatedHybridItemWeight = (
    itemMaxPoints,
    categoryMaxPoints,
    categoryWeight
) => {
    if (!categoryMaxPoints || !categoryWeight) return 0
    return roundValueToDecimals(
        (itemMaxPoints / categoryMaxPoints) * categoryWeight,
        CATEGORY_WEIGHT_DECIMAL_ACCURACY
    )
}

export const getItemWeightsOfHybridCategory = (category) =>
    category.items.map((item) =>
        getUpdatedHybridItemWeight(
            item.maximumPoints,
            category.maximumPoints,
            category.weight
        )
    )

export const getHybridCategoryReducedData = (
    category,
    achievingTargetGrade,
    gradesInAchievingTargetGrade
) => {
    let totalGrades = null
    let totalItemsWeight = 0
    let totalCategoryMaximumPoints = 0
    let knownGradesSum = 0
    let knownGradesWeightSum = 0
    let unknownGradesWeightSum = 0
    let unknownGradesCount = 0
    const weights = getItemWeightsOfHybridCategory(category)
    category.items.forEach((item, itemIdx) => {
        const { knownScores, grade, maximumPoints } = item
        const weight = weights[itemIdx]
        if (achievingTargetGrade && !knownScores) {
            totalGrades +=
                maximumPoints > 0
                    ? (gradesInAchievingTargetGrade[itemIdx] / maximumPoints) *
                      weight
                    : 0
        } else if (knownScores) {
            totalGrades = totalGrades === null ? 0 : totalGrades
            totalGrades +=
                maximumPoints > 0 ? (grade / maximumPoints) * weight : 0
        }
        totalItemsWeight += weight
        totalCategoryMaximumPoints += maximumPoints
        if (knownScores) {
            knownGradesSum +=
                maximumPoints > 0 ? (grade / maximumPoints) * weight : 0
            knownGradesWeightSum += weight
        } else {
            unknownGradesWeightSum += weight
            unknownGradesCount += 1
        }
    })
    return {
        totalGrades, // Total grades can be actual grades or achievable grades
        totalItemsWeight,
        totalCategoryMaximumPoints,
        totalCategoryWeight: category.weight,
        knownGradesSum, // Similar to totalGrades but it's always sum of actual grades
        knownGradesWeightSum,
        unknownGradesWeightSum,
        unknownGradesCount,
        maxAchievableGrade: knownGradesSum + unknownGradesWeightSum,
    }
}

export const getTotalItemsWeightOfHybridCategory = (category) => {
    if (category.maximumPoints === 0) return 0
    return roundValueToDecimals(
        category.items.reduce(
            (weightSum, item) =>
                weightSum +
                (item.maximumPoints / category.maximumPoints) * category.weight,
            0
        ),
        CATEGORY_WEIGHT_DECIMAL_ACCURACY
    )
}

export const getRemainingWeightOfHybridCategory = (category) =>
    roundValueToDecimals(
        category.weight - getTotalItemsWeightOfHybridCategory(category),
        CATEGORY_WEIGHT_DECIMAL_ACCURACY
    )

export const getTotalItemsMaxPointsOfHybridCategory = (category) =>
    roundValueToDecimals(
        category.items.reduce(
            (maxPointsSum, item) => maxPointsSum + item.maximumPoints,
            0
        ),
        POINTS_DECIMAL_ACCURACY
    )

export const getRemainingMaxPointsOfHybridCategory = (category) =>
    roundValueToDecimals(
        category.maximumPoints -
            getTotalItemsMaxPointsOfHybridCategory(category),
        POINTS_DECIMAL_ACCURACY
    )

export const getWeightOfHybridCategory = (category) => category.weight || 0

export const getMaximumPointsOfHybridCategory = (category) =>
    category.maximumPoints || 0

export const updateCategoriesWithTypeUpdateToHybrid = (
    categories,
    categoryIndex
) => {
    const hybridCategory = categories[categoryIndex]
    hybridCategory.weight = hybridCategory.weight || 0
    hybridCategory.maximumPoints =
        hybridCategory.maximumPoints ||
        hybridCategory.items.reduce((sum, item) => sum + item.maximumPoints, 0)
    const updatedItemWeights = getItemWeightsOfHybridCategory(hybridCategory)
    updatedItemWeights.forEach((updatedWeight, idx) => {
        hybridCategory.items[idx].weight = updatedWeight
    })
}

const getCleanHybridCategoryItem = (item) => {
    const { name, description, knownScores, grade, maximumPoints } = item
    return {
        name,
        description,
        knownScores,
        grade,
        maximumPoints,
    }
}

export const getCleanHybridCategory = (category) => {
    const { name, categoryType, weight, maximumPoints, items } = category
    return {
        name,
        categoryType,
        weight,
        maximumPoints,
        items: items.map(getCleanHybridCategoryItem),
    }
}
