import * as React from 'react'
import PropTypes from 'prop-types'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useDispatch, useSelector } from 'react-redux'
import {
    IconButton,
    Paper,
    TableFooter,
    Tooltip,
    Typography,
} from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {
    courseCategoryAdd,
    courseCategoryDelete,
    courseCategoryItemAdd,
    courseCategoryItemDelete,
    courseCategoryItemDescriptionUpdate,
    courseCategoryItemGradeUpdate,
    courseCategoryItemKnownScoreSwitch,
    courseCategoryItemMaxPointsUpdate,
    courseCategoryItemNameUpdate,
    courseCategoryItemWeightUpdate,
    courseCategoryMaxPointsUpdate,
    courseCategoryNameUpdate,
    courseCategoryTypeUpdate,
    courseCategoryWeightUpdate,
    setErrorForCourseCategoryItemKeyAtIndex,
    setErrorForCourseCategoryKeyAtIndex,
    setErrorForKey,
} from '../../redux'
import CategoryRow from './CategoryRow'
import {
    tableFooterTheme,
    tableHeaderCellPrimaryTheme,
    tableHeaderRowPrimaryTheme,
} from './Theme'
import {
    getItemWeightsOfCategories,
    getMaximumPointsOfCategories,
    getTotalCategoriesWeights,
    getWeightsOfCategories,
} from '../../redux/course/helpers/categoryHelper'

export default function GradeTable({ currentTotalGrade }) {
    const dispatch = useDispatch()
    const categories = useSelector(
        (state) => state.currentCourseData && state.currentCourseData.categories
    )
    const achievingTargetGrade = useSelector(
        (state) =>
            state.currentCourseData &&
            state.currentCourseData.achievingTargetGrade
    )
    const gradesInAchievingTargetGrade = useSelector(
        (state) =>
            state.currentCourseData &&
            state.currentCourseData.gradesInAchievingTargetGrade
    )
    const totalMaxPointsPerCategory = useSelector(
        (state) =>
            state.currentCourseData &&
            state.currentCourseData.totalMaxPointsPerCategory
    )
    const errorData = useSelector((state) => state.errorData)
    const categoriesErrorData = useSelector(
        (state) => state.errorData && state.errorData.categoriesErrors
    )

    const [categoriesItemWeights, setCategoriesItemWeights] = React.useState(
        getItemWeightsOfCategories(categories)
    )
    const [categoriesWeights, setCategoriesWeights] = React.useState(
        getWeightsOfCategories(categories)
    )
    const [categoriesMaximumPoints, setCategoriesMaximumPoints] =
        React.useState(getMaximumPointsOfCategories(categories))
    const [totalCategoriesWeight, setTotalCategoriesWeight] = React.useState(
        getTotalCategoriesWeights(categories)
    )
    const [categoryTypeTooltipIsOpen, setCategoryTypeTooltipIsOpen] =
        React.useState(false)

    React.useEffect(() => {
        setCategoriesItemWeights(getItemWeightsOfCategories(categories))
        setCategoriesWeights(getWeightsOfCategories(categories))
        setCategoriesMaximumPoints(getMaximumPointsOfCategories(categories))
        setTotalCategoriesWeight(getTotalCategoriesWeights(categories))
    }, [categories])

    const onCategoryNameChange = (categoryIndex, updatedCategoryName) => {
        let errorMessage = null
        if (updatedCategoryName.trim().length === 0)
            errorMessage = 'Enter category name'
        dispatch(
            setErrorForCourseCategoryKeyAtIndex(
                categoryIndex,
                'name',
                errorMessage
            )
        )
        dispatch(courseCategoryNameUpdate(categoryIndex, updatedCategoryName))
    }

    const verifyCategoryItemsMaximumPointsSum = (categoryIndex) => {
        let errorMessage = null
        const category = categories[categoryIndex]
        if (['HYBRID', 'POINTS_BASED'].indexOf(category.categoryType) !== -1) {
            const categoryItemsMaxPointsSum = category.items.reduce(
                (sum, item) => sum + item.maximumPoints,
                0
            )
            if (categoryItemsMaxPointsSum > category.maximumPoints) {
                errorMessage =
                    'Total item maximum points more than category maximum points'
            }
        }
        dispatch(
            setErrorForCourseCategoryKeyAtIndex(
                categoryIndex,
                'maximumPoints',
                errorMessage
            )
        )
    }

    const verifyCategoriesWeightSum = () => {
        let errorMessage = null
        const categoriesWeightSum = getTotalCategoriesWeights(categories)
        if (categoriesWeightSum !== 100)
            errorMessage = 'Total category weights not equal to 100'
        dispatch(setErrorForKey('totalWeights', errorMessage))
    }

    const onCategoryTypeChange = (categoryIndex, updatedCategoryType) => {
        dispatch(courseCategoryTypeUpdate(categoryIndex, updatedCategoryType))
        verifyCategoryItemsMaximumPointsSum(categoryIndex)
        verifyCategoriesWeightSum()
    }

    const onCategoryMaxPointsChange = (categoryIndex, updatedMaxPoints) => {
        let errorMessage = null
        if (updatedMaxPoints < 0) errorMessage = "Can't be less than 0"
        else if (updatedMaxPoints > 10000)
            errorMessage = "Can't be more than 10000"
        dispatch(
            setErrorForCourseCategoryKeyAtIndex(
                categoryIndex,
                'maxPoints',
                errorMessage
            )
        )
        dispatch(
            courseCategoryMaxPointsUpdate(
                categoryIndex,
                Number(updatedMaxPoints)
            )
        )
        verifyCategoryItemsMaximumPointsSum(categoryIndex)
    }

    const onCategoryWeightChange = (categoryIndex, updatedWeight) => {
        let errorMessage = null
        if (updatedWeight < 0) errorMessage = "Can't be less than 0"
        else if (updatedWeight > 100) errorMessage = "Can't be more than 100"
        dispatch(
            setErrorForCourseCategoryKeyAtIndex(
                categoryIndex,
                'weight',
                errorMessage
            )
        )
        dispatch(
            courseCategoryWeightUpdate(categoryIndex, Number(updatedWeight))
        )
        verifyCategoriesWeightSum()
    }

    const onCategoryItemWeightChange = (
        categoryIndex,
        categoryItemIndex,
        updatedWeight
    ) => {
        let errorMessage = null
        if (updatedWeight < 0) errorMessage = "Can't be less than 0"
        else if (
            categories[categoryIndex].categoryType === 'HYBRID' &&
            updatedWeight > categories[categoryIndex].weight
        ) {
            errorMessage = "Can't be more than category weight"
        }
        dispatch(
            setErrorForCourseCategoryItemKeyAtIndex(
                categoryIndex,
                categoryItemIndex,
                'weight',
                errorMessage
            )
        )
        dispatch(
            courseCategoryItemWeightUpdate(
                categoryIndex,
                categoryItemIndex,
                Number(updatedWeight)
            )
        )
        verifyCategoriesWeightSum()
    }

    const deleteCategory = (categoryIdx) => {
        dispatch(courseCategoryDelete(categoryIdx))
        verifyCategoriesWeightSum()
    }

    const deleteCategoryItem = (categoryIdx, itemIdx) => {
        dispatch(courseCategoryItemDelete(categoryIdx, itemIdx))
        verifyCategoriesWeightSum()
    }

    const onCategoryItemNameChange = (
        categoryIndex,
        itemIndex,
        updatedItemName
    ) => {
        let errorMessage = null
        if (updatedItemName.trim().length === 0)
            errorMessage = 'Enter category item name'
        dispatch(
            setErrorForCourseCategoryItemKeyAtIndex(
                categoryIndex,
                itemIndex,
                'name',
                errorMessage
            )
        )
        dispatch(
            courseCategoryItemNameUpdate(
                categoryIndex,
                itemIndex,
                updatedItemName
            )
        )
    }

    const onCategoryItemDescriptionChange = (
        categoryIndex,
        itemIndex,
        updatedItemDescription
    ) => {
        let errorMessage = null
        if (updatedItemDescription.length > 256)
            errorMessage = 'Item description too long'
        dispatch(
            setErrorForCourseCategoryItemKeyAtIndex(
                categoryIndex,
                itemIndex,
                'description',
                errorMessage
            )
        )
        dispatch(
            courseCategoryItemDescriptionUpdate(
                categoryIndex,
                itemIndex,
                updatedItemDescription
            )
        )
    }

    const onKnownScoresSwitch = (
        categoryIndex,
        categoryItemIndex,
        updatedKnownScore
    ) => {
        dispatch(
            courseCategoryItemKnownScoreSwitch(
                categoryIndex,
                categoryItemIndex,
                updatedKnownScore
            )
        )
    }

    const onGradeChange = (categoryIndex, categoryItemIndex, updatedGrade) => {
        let errorMessage = null
        if (updatedGrade < 0) errorMessage = "Can't be less than 0"
        dispatch(
            setErrorForCourseCategoryItemKeyAtIndex(
                categoryIndex,
                categoryItemIndex,
                'grade',
                errorMessage
            )
        )
        dispatch(
            courseCategoryItemGradeUpdate(
                categoryIndex,
                categoryItemIndex,
                Number(updatedGrade)
            )
        )
    }

    const onCategoryItemMaximumPointChange = (
        categoryIndex,
        categoryItemIndex,
        updatedMaximumPoints
    ) => {
        let errorMessage = null
        if (updatedMaximumPoints < 0) errorMessage = "Can't be less than 0"
        else if (updatedMaximumPoints > 10000)
            errorMessage = "Can't be more than 10000"
        dispatch(
            setErrorForCourseCategoryItemKeyAtIndex(
                categoryIndex,
                categoryItemIndex,
                'maximumPoints',
                errorMessage
            )
        )
        dispatch(
            courseCategoryItemMaxPointsUpdate(
                categoryIndex,
                categoryItemIndex,
                Number(updatedMaximumPoints)
            )
        )
        verifyCategoryItemsMaximumPointsSum(categoryIndex)
    }

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <colgroup>
                    <col style={{ width: '5%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '25%' }} />
                    <col style={{ width: '25%' }} />
                    <col style={{ width: '5%' }} />
                </colgroup>
                <TableHead>
                    <TableRow sx={tableHeaderRowPrimaryTheme}>
                        <TableCell />
                        <TableCell sx={tableHeaderCellPrimaryTheme}>
                            Category
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={tableHeaderCellPrimaryTheme}
                        >
                            Type
                            <Tooltip
                                className="ml-1"
                                onClick={() => {
                                    setCategoryTypeTooltipIsOpen(true)
                                }}
                                onMouseOut={() =>
                                    setCategoryTypeTooltipIsOpen(false)
                                }
                                onOpen={() => {
                                    setCategoryTypeTooltipIsOpen(true)
                                }}
                                open={categoryTypeTooltipIsOpen}
                                sx={{ paddingBottom: '2px' }}
                                title={
                                    <>
                                        <Typography
                                            key={0}
                                            display="block"
                                            variant="caption"
                                        >
                                            Weight-based: Grade is based on
                                            weights - max points can be
                                            arbitrary
                                        </Typography>
                                        <Typography
                                            key={1}
                                            display="block"
                                            variant="caption"
                                        >
                                            Points-based: Grade is based on
                                            points only, weights play no role
                                        </Typography>
                                        <Typography
                                            key={2}
                                            display="block"
                                            variant="caption"
                                        >
                                            Hybrid: Max points and max weights
                                            are predefined for each category,
                                            grade is based on max points and max
                                            weights for each category
                                        </Typography>
                                    </>
                                }
                            >
                                <InfoOutlinedIcon fontSize="small" />
                            </Tooltip>
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={tableHeaderCellPrimaryTheme}
                        >
                            Max Points
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={tableHeaderCellPrimaryTheme}
                        >
                            Weight
                        </TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {categories.map((category, idx) => (
                        <CategoryRow
                            // eslint-disable-next-line react/no-array-index-key
                            key={idx}
                            achievingTargetGrade={achievingTargetGrade}
                            addItem={() => {
                                dispatch(courseCategoryItemAdd(idx))
                                verifyCategoryItemsMaximumPointsSum(idx)
                            }}
                            category={category}
                            deleteCategory={() => deleteCategory(idx)}
                            deleteItem={(itemIdx) =>
                                deleteCategoryItem(idx, itemIdx)
                            }
                            enableModifyGrade={!achievingTargetGrade}
                            errorData={categoriesErrorData[idx]}
                            gradesInAchievingTargetGrade={
                                gradesInAchievingTargetGrade[idx]
                            }
                            itemWeights={categoriesItemWeights[idx]}
                            maximumPoints={categoriesMaximumPoints[idx]}
                            onCategoryMaxPointsChange={(value) =>
                                onCategoryMaxPointsChange(idx, value)
                            }
                            onCategoryNameChange={(value) =>
                                onCategoryNameChange(idx, value)
                            }
                            onCategoryTypeChange={(type) =>
                                onCategoryTypeChange(idx, type)
                            }
                            onCategoryWeightChange={(value) =>
                                onCategoryWeightChange(idx, value)
                            }
                            onItemDescriptionChange={(itemIndex, value) =>
                                onCategoryItemDescriptionChange(
                                    idx,
                                    itemIndex,
                                    value
                                )
                            }
                            onItemGradeChange={(itemIndex, value) =>
                                onGradeChange(idx, itemIndex, value)
                            }
                            onItemKnownScoreChange={(itemIndex, value) =>
                                onKnownScoresSwitch(idx, itemIndex, value)
                            }
                            onItemMaximumPointChange={(itemIndex, value) =>
                                onCategoryItemMaximumPointChange(
                                    idx,
                                    itemIndex,
                                    value
                                )
                            }
                            onItemNameChange={(itemIndex, value) =>
                                onCategoryItemNameChange(idx, itemIndex, value)
                            }
                            onItemWeightChange={(itemIndex, value) =>
                                onCategoryItemWeightChange(
                                    idx,
                                    itemIndex,
                                    value
                                )
                            }
                            totalMaxPoints={totalMaxPointsPerCategory[idx]}
                            weight={categoriesWeights[idx]}
                        />
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow sx={tableFooterTheme}>
                        <TableCell colSpan={2} size="small">
                            <IconButton
                                aria-label="add a category"
                                disabled={achievingTargetGrade}
                                edge="end"
                                onClick={() => dispatch(courseCategoryAdd())}
                            >
                                <AddCircleOutlineIcon />
                                <Typography marginLeft="12px">
                                    Add a category
                                </Typography>
                            </IconButton>
                        </TableCell>
                        <TableCell align="center" colSpan={2} width="40%">
                            <Typography>
                                Grade: <b>{currentTotalGrade}</b>
                            </Typography>
                        </TableCell>
                        <TableCell
                            align="center"
                            className="container"
                            colSpan={2}
                        >
                            <Typography
                                className={
                                    errorData.totalWeights ? 'error' : ''
                                }
                            >
                                {' '}
                                <b>{totalCategoriesWeight}</b>
                            </Typography>
                            {errorData.totalWeights ? (
                                <Typography
                                    className="error"
                                    sx={{ fontSize: '0.75rem' }}
                                >
                                    {errorData.totalWeights}
                                </Typography>
                            ) : null}
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    )
}

GradeTable.propTypes = {
    currentTotalGrade: PropTypes.number,
}

GradeTable.defaultProps = {
    currentTotalGrade: 0,
}
