import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
    clearErrorForKey as clearErrorForKeyRedux,
    generateResetPasswordEmail as generateResetPasswordEmailRedux,
    setErrorForKey as setErrorForKeyRedux,
} from '../../redux'
import { validateEmail } from '../../utils'

function GenerateResetPasswordForm({
    userData,
    resetPasswordData,
    errorData,
    generateResetPasswordEmail,
    setErrorForKey,
    clearErrorForKey,
}) {
    const [email, setEmail] = useState('')
    useEffect(
        () => () => {
            clearErrorForKey('email')
        },
        [clearErrorForKey]
    )

    const onEmailChange = (updatedEmail) => {
        let errorMessage = null
        if (!validateEmail(updatedEmail))
            errorMessage = 'Invalid email provided'
        setErrorForKey('email', errorMessage)
        setEmail(updatedEmail)
    }

    const isFormValid = () => errorData.email === null
    let emailAddressClassNames = 'form-control'
    if (errorData.email !== undefined) {
        if (errorData.email) {
            emailAddressClassNames += ' form-input-invalid'
        } else {
            emailAddressClassNames += ' form-input-valid'
        }
    }
    return (
        <form>
            <div className="form-group">
                <label className="input-max-400" htmlFor="email">
                    Enter your registered email address
                    <input
                        className={emailAddressClassNames}
                        id="email"
                        maxLength={50}
                        onChange={(e) => onEmailChange(e.target.value)}
                        type="email"
                        value={email}
                    />
                </label>
            </div>
            <button
                className="btn btn-primary"
                disabled={
                    userData.loading ||
                    resetPasswordData.loading ||
                    !isFormValid()
                }
                onClick={() => generateResetPasswordEmail(email)}
                type="submit"
            >
                Proceed
            </button>
        </form>
    )
}

GenerateResetPasswordForm.propTypes = {
    userData: PropTypes.shape({
        loading: PropTypes.bool,
    }).isRequired,
    resetPasswordData: PropTypes.shape({
        loading: PropTypes.bool,
    }).isRequired,
    errorData: PropTypes.shape({
        email: PropTypes.string,
        password: PropTypes.string,
    }).isRequired,
    generateResetPasswordEmail: PropTypes.func.isRequired,
    setErrorForKey: PropTypes.func.isRequired,
    clearErrorForKey: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    resetPasswordData: state.resetPasswordData,
    errorData: state.errorData,
})

const mapDispatchToProps = (dispatch) => ({
    generateResetPasswordEmail: (email) =>
        dispatch(generateResetPasswordEmailRedux(email)),
    setErrorForKey: (key, errorMessage) =>
        dispatch(setErrorForKeyRedux(key, errorMessage)),
    clearErrorForKey: (key) => dispatch(clearErrorForKeyRedux(key)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GenerateResetPasswordForm)
