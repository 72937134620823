import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import ReactToPrint from 'react-to-print'
import Meters from './commons/Meters'
import '../css/Report.css'
import { roundValueToDecimals } from '../utils'
import { GRADE_DECIMAL_ACCURACY } from '../utils/constants'

class ComponentToPrint extends React.Component {
    render() {
        const {
            username,
            courseName,
            currentGrade,
            knownGradesWeightSum,
            projectedPercent,
            targetPercent,
            meterDivisions,
            maxAchievableGrade,
            categories,
            achievableTargetGrades,
        } = this.props
        return (
            <div className="mx-5 report">
                <nav className="px-3 py-2">
                    <div className="navbar-brand">
                        <img
                            alt="logo"
                            id="nav-logo"
                            src="/logo.png"
                            style={{ width: '70px', height: '70px' }}
                        />
                        <h4 className="ml-4" style={{ alignSelf: 'center' }}>
                            <i>my</i>Academic
                        </h4>
                    </div>
                </nav>
                <div className="column ml-3 mr-3">
                    <span className="mt-3 pl-2 report-heading row">
                        <b>{`${username}'s Progress Report`}</b>
                        <i className="ml-2">
                            {' '}
                            as of {new Date().toDateString()}
                        </i>
                    </span>
                    <span className="row">
                        <b>Course</b>: {courseName}
                    </span>
                    <span className="row">
                        <b>Grade earned to date</b>: {currentGrade} /{' '}
                        {knownGradesWeightSum}
                    </span>
                </div>
                <div
                    className="row mb-0"
                    style={{ justifyContent: 'space-evenly' }}
                >
                    <span>
                        <b className="row pr-5">
                            Predicted Grade<div className="superscript">*</div>
                        </b>
                    </span>
                    <span>
                        <b className="row pl-3">Target Grade</b>
                    </span>
                </div>
                <Meters
                    animate={false}
                    divisions={meterDivisions}
                    meter1Label=""
                    meter2Label=""
                    projectedPercent={projectedPercent}
                    showRangeSelector={false}
                    showRangeValues={false}
                    targetPercent={targetPercent}
                />
                <div
                    className="row mx-0 mt-2"
                    style={{ justifyContent: 'space-evenly' }}
                >
                    <div className="row">
                        <div className="bg-red report-meter-color-box" />
                        <span className="report-meter-desc">
                            Needs Attention
                        </span>
                    </div>
                    <div className="row">
                        <div className="bg-yellow report-meter-color-box" />
                        <span className="report-meter-desc">Warning</span>
                    </div>
                    <div className="row">
                        <div className="bg-green report-meter-color-box" />
                        <span className="report-meter-desc">On Track</span>
                    </div>
                </div>
                <span>
                    <b>Maximum possible grade based on known points</b>:{' '}
                    {roundValueToDecimals(
                        maxAchievableGrade,
                        GRADE_DECIMAL_ACCURACY
                    )}
                </span>
                <table className="report-table" width="100%">
                    <colgroup>
                        <col span="1" style={{ width: '35%' }} />
                        <col span="1" style={{ width: '35%' }} />
                        <col span="1" style={{ width: '30%' }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <td colSpan={3}>
                                <b>Grades already received (known points)</b>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {categories.map((category) => {
                            const knownScoreItems = category.items.filter(
                                (item) => item.knownScores
                            )
                            return knownScoreItems.map((item, itemIdx) => (
                                <tr key={item.name}>
                                    {itemIdx === 0 ? (
                                        <td
                                            rowSpan={knownScoreItems.length}
                                            style={{ verticalAlign: 'top' }}
                                        >
                                            <b>{category.name}</b>
                                        </td>
                                    ) : null}
                                    <td>{item.name}</td>
                                    <td
                                        className="pr-5"
                                        style={{ textAlign: 'right' }}
                                    >{`${item.grade}/${item.maximumPoints}`}</td>
                                </tr>
                            ))
                        })}
                    </tbody>
                </table>
                <table className="report-table mt-4" width="100%">
                    <colgroup>
                        <col span="1" style={{ width: '35%' }} />
                        <col span="1" style={{ width: '35%' }} />
                        <col span="1" style={{ width: '30%' }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <td colSpan={3}>
                                <b>
                                    Here is a possible way to achieve your
                                    target grade
                                </b>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {categories.map((category, categoryIdx) => {
                            const unknownScoreItems = category.items
                                .map((item, itemIdx) => {
                                    return { ...item, index: itemIdx }
                                })
                                .filter((item) => !item.knownScores)
                            return unknownScoreItems.map((item, itemIdx) => (
                                <tr key={item.name}>
                                    {itemIdx === 0 ? (
                                        <td
                                            rowSpan={unknownScoreItems.length}
                                            style={{ verticalAlign: 'top' }}
                                        >
                                            <b>{category.name}</b>
                                        </td>
                                    ) : null}
                                    <td>{item.name}</td>
                                    <td
                                        className="pr-5"
                                        style={{ textAlign: 'right' }}
                                    >
                                        {`${
                                            achievableTargetGrades[categoryIdx][
                                                item.index
                                            ]
                                        }/${item.maximumPoints}`}
                                    </td>
                                </tr>
                            ))
                        })}
                    </tbody>
                </table>
                <span>
                    <b>Suggestion: </b>
                    {projectedPercent >= targetPercent
                        ? 'You are on track towards achieving your goal. Review the practice quizzes to hone your skills.'
                        : 'Your predicted grade is less than target grade. Hence you need to pay more attention towards achieving your goal. Review the practice quizzes to boost your grade.'}
                </span>
                <div className="column ml-3 mr-3">
                    <span className="superscript row">
                        *IMPORTANT: The predicted grade is based on current
                        scores and does not guarantee of final grade, which
                        depends on future performance.{' '}
                        <i>
                            Calculations are based on information provided and
                            past performance.
                        </i>
                    </span>
                </div>
            </div>
        )
    }
}

ComponentToPrint.propTypes = {
    username: PropTypes.string.isRequired,
    courseName: PropTypes.string.isRequired,
    currentGrade: PropTypes.number.isRequired,
    knownGradesWeightSum: PropTypes.number.isRequired,
    projectedPercent: PropTypes.number.isRequired,
    targetPercent: PropTypes.number.isRequired,
    meterDivisions: PropTypes.arrayOf(PropTypes.number).isRequired,
    maxAchievableGrade: PropTypes.number.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    categories: PropTypes.array.isRequired,
    achievableTargetGrades: PropTypes.arrayOf(
        PropTypes.arrayOf(PropTypes.number)
    ).isRequired,
}

function GenerateButton(disabled) {
    return (
        <button
            className="btn btn-primary m-2"
            disabled={disabled}
            id="generate"
            type="button"
        >
            Generate Report
        </button>
    )
}

function Report({
    disabled,
    username,
    courseName,
    currentGrade,
    knownGradesWeightSum,
    projectedPercent,
    targetPercent,
    meterDivisions,
    maxAchievableGrade,
    categories,
    achievableTargetGrades,
    beforePrint = () => {},
}) {
    const componentRef = useRef(null)
    return (
        <div>
            <ReactToPrint
                content={() => componentRef.current}
                onBeforePrint={() => beforePrint()}
                trigger={() => GenerateButton(disabled)}
            />
            <div style={{ position: 'absolute', left: '-99999px' }}>
                <ComponentToPrint
                    ref={componentRef}
                    achievableTargetGrades={achievableTargetGrades}
                    categories={categories}
                    courseName={courseName}
                    currentGrade={currentGrade}
                    knownGradesWeightSum={knownGradesWeightSum}
                    maxAchievableGrade={maxAchievableGrade}
                    meterDivisions={meterDivisions}
                    projectedPercent={projectedPercent}
                    targetPercent={targetPercent}
                    username={username}
                />
            </div>
        </div>
    )
}

Report.propTypes = {
    disabled: PropTypes.bool,
    username: PropTypes.string.isRequired,
    courseName: PropTypes.string.isRequired,
    currentGrade: PropTypes.number.isRequired,
    knownGradesWeightSum: PropTypes.number.isRequired,
    projectedPercent: PropTypes.number.isRequired,
    targetPercent: PropTypes.number.isRequired,
    meterDivisions: PropTypes.arrayOf(PropTypes.number).isRequired,
    maxAchievableGrade: PropTypes.number.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    categories: PropTypes.array.isRequired,
    achievableTargetGrades: PropTypes.arrayOf(
        PropTypes.arrayOf(PropTypes.number)
    ).isRequired,
    beforePrint: PropTypes.func,
}

Report.defaultProps = {
    beforePrint: () => {},
    disabled: false,
}

export default Report
