import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Metrics from '../utils/Metrics'
import LoginPrompt from './commons/LoginPrompt'

function LandingPage() {
    const history = useHistory()
    const [showLoginDialog, setShowLoginDialog] = useState(false)
    const userData = useSelector((state) => state.userData)
    const isFetchingUser = userData && userData.loading
    const userLoggedIn =
        userData && userData.user && userData.user.id !== undefined
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const goToCareerPlanning = (e) => {
        Metrics.event({
            category: 'button',
            action: 'click',
            label: 'go to careers',
        })
        e.preventDefault()
        history.push('/careers')
    }

    const goToGradeAnalyzerIfLoggedIn = (e) => {
        e.preventDefault()
        if (!isFetchingUser && userLoggedIn) {
            history.push('/grade-analyzer')
        } else {
            setShowLoginDialog(true)
        }
    }
    return (
        <div className="main-container">
            <LoginPrompt
                history={history}
                message="To access this page, you need to login. Proceed?"
                onClose={() => setShowLoginDialog(false)}
                open={showLoginDialog}
            />
            <header
                className="page-header header container-fluid"
                style={{ backgroundImage: 'url("images/book.jpg")' }}
            >
                <div className="description">
                    <h1
                        className="mt-5"
                        style={{ color: 'rgba(37, 39, 37, 0.918)' }}
                    >
                        Welcome
                        {userData && userData.user && userData.user.id
                            ? `, ${userData.user.firstName}`
                            : null}{' '}
                        to<i> my</i>
                        Academic
                    </h1>
                    <h3 className="mt-4" style={{ color: '#0055A2' }}>
                        Assisting students for academic success
                    </h3>
                    <button
                        className="btn btn-primary btn-lg mt-5"
                        onClick={() => {
                            window.location.href = '#Academics'
                        }}
                        type="button"
                    >
                        Learn More
                    </button>
                </div>
            </header>

            <div className="container features">
                <div
                    className="row"
                    id="Academics"
                    style={{ paddingTop: '10vh' }}
                >
                    <button
                        className="col-lg-6 col-md-6 col-sm-12 feature-container"
                        onClick={goToCareerPlanning}
                        type="button"
                    >
                        <div className="btn btn-secondary no-border feature-tile">
                            <h3 className="feature-title">Career Planning</h3>
                            <div className="feature-image-container">
                                <img
                                    alt="Career staircase"
                                    className="img-fluid"
                                    src="images/stairs-5957112_1920_Copy.jpg"
                                />
                            </div>
                            <ul className="feature-bullets">
                                <li>Explore career options</li>
                                <li>Plan future course of study</li>
                                <li>Learn about job opportunities</li>
                            </ul>
                        </div>
                    </button>
                    <button
                        className="col-lg-6 col-md-6 col-sm-12 feature-container"
                        onClick={goToGradeAnalyzerIfLoggedIn}
                        type="button"
                    >
                        <div className="btn btn-secondary no-border feature-tile">
                            <h3 className="feature-title">
                                Achieve Academic Goals
                            </h3>
                            <div className="feature-image-container">
                                <img
                                    alt="Ecstatic student"
                                    className="img-fluid"
                                    src="images/student-4311761_1920.jpg"
                                />
                            </div>
                            <ul className="feature-bullets">
                                <li>Set target goals</li>
                                <li>
                                    Prepare for tests and quizzes with
                                    confidence
                                </li>
                                <li>Optimize and boost grades</li>
                            </ul>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default LandingPage
