import { combineReducers } from 'redux'
import userReducer from './user/userReducer'
import courseReducer from './course/courseReducer'
import errorReducer from './error/errorReducer'
import userVerificationReducer from './userVerification/userVerificationReducer'
import resetPasswordReducer from './resetPassword/resetPasswordReducer'
import contactUsReducer from './contactUs/contactUsReducer'
import quizMetaReducer from './quizMeta/quizMetaReducer'
import quizReducer from './quiz/quizReducer'
import quizCategoryMetaReducer from './quizCategory/quizCategoryReducer'
import savedCourseReducer from './savedCourse/savedCourseReducer'
import jobReducer from './job/jobReducer'

const rootReducer = combineReducers({
    userData: userReducer,
    currentCourseData: courseReducer,
    errorData: errorReducer,
    userVerificationData: userVerificationReducer,
    resetPasswordData: resetPasswordReducer,
    contactUsData: contactUsReducer,
    quizMetaData: quizMetaReducer,
    quizData: quizReducer,
    quizCategoryMetaData: quizCategoryMetaReducer,
    savedCourseData: savedCourseReducer,
    jobData: jobReducer,
})

export default rootReducer
