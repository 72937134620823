export const tableHeaderRowPrimaryTheme = { bgcolor: '#0055A2' }
export const tableHeaderCellPrimaryTheme = {
    color: 'white',
    fontWeight: 'bold',
}

export const tableHeaderRowSecondaryTheme = { bgcolor: '#33AAFF' }
export const tableHeaderCellSecondaryTheme = {
    color: 'white',
    fontWeight: 'bold',
}

export const tableFooterTheme = { bgcolor: '#F7F7F7' }
export const tableFooterCellTheme = { color: 'rgba(0, 0, 0, 0.6)' }
