import axios from 'axios'
import {
    USER_VERIFICATION_FAILURE,
    USER_VERIFICATION_REQUEST,
    USER_VERIFICATION_SUCCESS,
} from './userVerificationTypes'

export const userVerificationRequest = () => ({
    type: USER_VERIFICATION_REQUEST,
})

export const userVerificationSuccess = () => ({
    type: USER_VERIFICATION_SUCCESS,
})

export const userVerificationFailure = (errorMsg) => ({
    type: USER_VERIFICATION_FAILURE,
    payload: errorMsg,
})

export const verifyUserVerificationToken =
    (verificationToken) => (dispatch) => {
        dispatch(userVerificationRequest())
        axios
            .get(
                `${process.env.REACT_APP_BASE_URL}/user-verification/verify?token=${verificationToken}`
            )
            .then(() => {
                dispatch(userVerificationSuccess())
            })
            .catch((error) => {
                dispatch(
                    userVerificationFailure(
                        (error.response && error.response.data) ||
                            'Verification failed'
                    )
                )
            })
    }
