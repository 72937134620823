export const SET_ERROR_FOR_KEY = 'SET_ERROR_FOR_KEY'
export const CLEAR_ERROR_FOR_KEY = 'CLEAR_ERROR_FOR_KEY'

export const SET_ERROR_FOR_COURSE_CATEGORY_KEY_AT_INDEX =
    'SET_ERROR_FOR_COURSE_CATEGORY_KEY_AT_INDEX'
export const CLEAR_ERROR_FOR_COURSE_CATEGORY_INDEX =
    'CLEAR_ERROR_FOR_COURSE_CATEGORY_INDEX'
export const SET_ERROR_FOR_COURSE_CATEGORY_ITEM_KEY_AT_INDEX =
    'SET_ERROR_FOR_COURSE_CATEGORY_ITEM_KEY_AT_INDEX'
export const CLEAR_ERROR_FOR_COURSE_CATEGORY_ITEM_INDEX =
    'CLEAR_ERROR_FOR_COURSE_CATEGORY_ITEM_INDEX'
