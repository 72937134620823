import axios from 'axios'
import {
    clearErrorForCourseCategoryIndex,
    clearErrorForCourseCategoryItemIndex,
} from '../error/errorActions'
import {
    ACHIEVE_TARGET_GRADE,
    COURSE_CATEGORY_ADD,
    COURSE_CATEGORY_DELETE,
    COURSE_CATEGORY_ITEM_ADD,
    COURSE_CATEGORY_ITEM_DELETE,
    COURSE_CATEGORY_ITEM_DESCRIPTION_UPDATE,
    COURSE_CATEGORY_ITEM_GRADE_UPDATE,
    COURSE_CATEGORY_ITEM_KNOWN_SCORE_SWITCH,
    COURSE_CATEGORY_ITEM_MAX_POINTS_UPDATE,
    COURSE_CATEGORY_ITEM_NAME_UPDATE,
    COURSE_CATEGORY_ITEM_WEIGHT_UPDATE,
    COURSE_CATEGORY_MAX_POINTS_UPDATE,
    COURSE_CATEGORY_NAME_UPDATE,
    COURSE_CATEGORY_TYPE_UPDATE,
    COURSE_CATEGORY_WEIGHT_UPDATE,
    COURSE_DESCRIPTION_UPDATE,
    COURSE_GRADES_DELETE_FAILURE,
    COURSE_GRADES_DELETE_REQUEST,
    COURSE_GRADES_DELETE_SUCCESS,
    COURSE_GRADES_SAVE_FAILURE,
    COURSE_GRADES_SAVE_REQUEST,
    COURSE_GRADES_SAVE_SUCCESS,
    COURSE_GRADES_UPDATE_FAILURE,
    COURSE_GRADES_UPDATE_REQUEST,
    COURSE_GRADES_UPDATE_SUCCESS,
    COURSE_MEETING_UPDATE,
    COURSE_NAME_UPDATE,
    COURSE_NOTES_UPDATE,
    COURSE_SYLLABUS_UPDATE,
    COURSE_TARGET_GRADE_UPDATE,
    MODIFY_KNOWN_SCORES,
    RESET_COURSE_DATA,
    SAVED_COURSE_LOAD_FAILURE,
    SAVED_COURSE_LOAD_REQUEST,
    SAVED_COURSE_LOAD_SUCCESS,
    UPDATE_COURSE_METER_DIVISIONS,
} from './courseTypes'

export const courseTargetGradeUpdate = (targetGrade) => ({
    type: COURSE_TARGET_GRADE_UPDATE,
    payload: targetGrade,
})

export const courseNameUpdate = (courseName) => ({
    type: COURSE_NAME_UPDATE,
    payload: courseName,
})

export const courseDescriptionUpdate = (description) => ({
    type: COURSE_DESCRIPTION_UPDATE,
    payload: description,
})

export const courseSyllabusUpdate = (syllabus) => ({
    type: COURSE_SYLLABUS_UPDATE,
    payload: syllabus,
})

export const courseMeetingDetailsUpdate = (meetingDetails) => ({
    type: COURSE_MEETING_UPDATE,
    payload: meetingDetails,
})

export const courseNotesUpdate = (notes) => ({
    type: COURSE_NOTES_UPDATE,
    payload: notes,
})

export const courseCategoryNameUpdate = (categoryIndex, name) => ({
    type: COURSE_CATEGORY_NAME_UPDATE,
    payload: [categoryIndex, name],
})

export const courseCategoryTypeUpdate = (categoryIndex, categoryType) => ({
    type: COURSE_CATEGORY_TYPE_UPDATE,
    payload: [categoryIndex, categoryType],
})

export const courseCategoryItemNameUpdate = (
    categoryIndex,
    itemIndex,
    name
) => ({
    type: COURSE_CATEGORY_ITEM_NAME_UPDATE,
    payload: [categoryIndex, itemIndex, name],
})

export const courseCategoryItemDescriptionUpdate = (
    categoryIndex,
    itemIndex,
    description
) => ({
    type: COURSE_CATEGORY_ITEM_DESCRIPTION_UPDATE,
    payload: [categoryIndex, itemIndex, description],
})

export const courseCategoryItemKnownScoreSwitch = (
    categoryIndex,
    itemIndex,
    knownScore
) => ({
    type: COURSE_CATEGORY_ITEM_KNOWN_SCORE_SWITCH,
    payload: [categoryIndex, itemIndex, knownScore],
})

export const courseCategoryItemGradeUpdate = (
    categoryIndex,
    itemIndex,
    grade
) => ({
    type: COURSE_CATEGORY_ITEM_GRADE_UPDATE,
    payload: [categoryIndex, itemIndex, grade],
})

export const courseCategoryItemMaxPointsUpdate = (
    categoryIndex,
    itemIndex,
    maximumPoints
) => ({
    type: COURSE_CATEGORY_ITEM_MAX_POINTS_UPDATE,
    payload: [categoryIndex, itemIndex, maximumPoints],
})

export const courseCategoryMaxPointsUpdate = (categoryIndex, maxPoints) => ({
    type: COURSE_CATEGORY_MAX_POINTS_UPDATE,
    payload: [categoryIndex, maxPoints],
})

export const courseCategoryWeightUpdate = (categoryIndex, weight) => ({
    type: COURSE_CATEGORY_WEIGHT_UPDATE,
    payload: [categoryIndex, weight],
})

export const courseCategoryItemWeightUpdate = (
    categoryIndex,
    itemIndex,
    weight
) => ({
    type: COURSE_CATEGORY_ITEM_WEIGHT_UPDATE,
    payload: [categoryIndex, itemIndex, weight],
})

export const courseCategoryAdd = () => ({
    type: COURSE_CATEGORY_ADD,
})

export const courseCategoryItemAdd = (categoryIndex) => ({
    type: COURSE_CATEGORY_ITEM_ADD,
    payload: categoryIndex,
})

export const courseCategoryDelete = (categoryIndex) => (dispatch) => {
    dispatch({
        type: COURSE_CATEGORY_DELETE,
        payload: categoryIndex,
    })
    dispatch(clearErrorForCourseCategoryIndex(categoryIndex))
}

export const courseCategoryItemDelete =
    (categoryIndex, itemIndex) => (dispatch) => {
        dispatch({
            type: COURSE_CATEGORY_ITEM_DELETE,
            payload: [categoryIndex, itemIndex],
        })
        dispatch(clearErrorForCourseCategoryItemIndex(categoryIndex, itemIndex))
    }

export const achieveTargetGrade = () => ({
    type: ACHIEVE_TARGET_GRADE,
})

export const modifyKnownScores = () => ({
    type: MODIFY_KNOWN_SCORES,
})

export const updateMeterDivisions = (meterDivisions) => ({
    type: UPDATE_COURSE_METER_DIVISIONS,
    payload: meterDivisions,
})

export const courseGradesSaveRequest = () => ({
    type: COURSE_GRADES_SAVE_REQUEST,
})

export const courseGradesSaveSuccess = (courseGrades) => ({
    type: COURSE_GRADES_SAVE_SUCCESS,
    payload: courseGrades,
})

export const courseGradesSaveFailure = (errorMsg) => ({
    type: COURSE_GRADES_SAVE_FAILURE,
    payload: errorMsg,
})

export const resetCourseData = () => ({
    type: RESET_COURSE_DATA,
})

export const savedCourseLoadRequest = () => ({
    type: SAVED_COURSE_LOAD_REQUEST,
})

export const savedCourseLoadSuccess = (savedCourse) => ({
    type: SAVED_COURSE_LOAD_SUCCESS,
    payload: savedCourse,
})

export const savedCourseLoadFailure = (errorMsg) => ({
    type: SAVED_COURSE_LOAD_FAILURE,
    payload: errorMsg,
})

export const courseUpdateRequest = () => ({
    type: COURSE_GRADES_UPDATE_REQUEST,
})

export const courseUpdateSuccess = (course) => ({
    type: COURSE_GRADES_UPDATE_SUCCESS,
    payload: course,
})

export const courseUpdateFailure = (errorMsg) => ({
    type: COURSE_GRADES_UPDATE_FAILURE,
    payload: errorMsg,
})

export const courseDeleteRequest = () => ({
    type: COURSE_GRADES_DELETE_REQUEST,
})

export const courseDeleteSuccess = () => ({
    type: COURSE_GRADES_DELETE_SUCCESS,
})

export const courseDeleteFailure = (errorMsg) => ({
    type: COURSE_GRADES_DELETE_FAILURE,
    payload: errorMsg,
})

export const saveCourseGrades =
    (name, description, targetGrade, categories, meterDivisions) =>
    (dispatch) => {
        dispatch(courseGradesSaveRequest())
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}/course`,
            data: {
                name,
                description,
                targetGrade,
                categories,
                meterDivisions,
            },
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                const courseGrades = response.data
                dispatch(courseGradesSaveSuccess(courseGrades))
            })
            .catch((error) => {
                dispatch(
                    courseGradesSaveFailure(
                        (error.response && error.response.data) ||
                            'Saving course grades failed'
                    )
                )
            })
    }

export const fetchSavedCourse = (id) => (dispatch) => {
    dispatch(savedCourseLoadRequest())
    axios
        .get(`${process.env.REACT_APP_BASE_URL}/course/${id}`)
        .then((res) => {
            const course = res.data
            if (!course) {
                dispatch(savedCourseLoadFailure('Course not found'))
            } else {
                dispatch(savedCourseLoadSuccess(course))
            }
        })
        .catch((err) => {
            dispatch(
                savedCourseLoadFailure(
                    (err.response && err.response.data) ||
                        'Error loading the course'
                )
            )
        })
}

export const updateCourseGrades =
    (id, name, description, notes, targetGrade, categories, meterDivisions) =>
    (dispatch) => {
        dispatch(courseUpdateRequest())
        axios({
            method: 'patch',
            url: `${process.env.REACT_APP_BASE_URL}/course/${id}`,
            data: {
                name,
                description,
                notes,
                targetGrade,
                categories,
                meterDivisions,
            },
            headers: { 'Content-Type': 'application/json' },
        })
            .then(() => {
                dispatch(courseUpdateSuccess())
            })
            .catch((error) => {
                dispatch(
                    courseUpdateFailure(
                        (error.response && error.response.data) ||
                            'Updating course grades failed'
                    )
                )
            })
    }
export const updateCourseFields = (id, fields) => (dispatch) => {
    dispatch(courseUpdateRequest())
    axios({
        method: 'patch',
        url: `${process.env.REACT_APP_BASE_URL}/course/${id}`,
        data: fields,
        headers: { 'Content-Type': 'application/json' },
    })
        .then(() => {
            dispatch(courseUpdateSuccess())
        })
        .catch((error) => {
            dispatch(
                courseUpdateFailure(
                    (error.response && error.response.data) ||
                        'Updating course grades failed'
                )
            )
        })
}

export const deleteCourse = (id) => (dispatch) => {
    dispatch(courseDeleteRequest())
    axios({
        method: 'delete',
        url: `${process.env.REACT_APP_BASE_URL}/course/${id}`,
        headers: { 'Content-Type': 'application/json' },
    })
        .then(() => {
            dispatch(courseDeleteSuccess())
        })
        .catch((error) => {
            dispatch(
                courseDeleteFailure(
                    (error.response && error.response.data) ||
                        'Deleting course grades failed'
                )
            )
        })
}
